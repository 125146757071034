import React from "react";

import Lottie from "react-lottie";

import { randomItemFromArray } from "utils/oneLiners";
import { useDynamicLottieFile } from "hooks/useDynamicMedia";

import styled, { css } from "styled-components";

const StyledDrCleverFullScreen = styled.section`
  ${({ opaque }) => {
    return css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: ${opaque ? "100" : "1"};
      background-color: ${opaque ? "#ffffff" : ""};
    `;
  }}
`;

export const DrCleverFullScreen = props => {
  const { isLoading, opaque } = props;
  const drCleverLotties = [
    "drclever.json",
    "drcleverhappy.json",
    "drcleverlove.json",
    "drclevermask.json",
  ];
  const lottieToShow = randomItemFromArray(drCleverLotties);
  const animationData = useDynamicLottieFile(lottieToShow);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    isStopped: !isLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <StyledDrCleverFullScreen opaque={opaque}>
      <Lottie options={defaultOptions} height={72} width={72} />
    </StyledDrCleverFullScreen>
  );
};

export default DrCleverFullScreen;
