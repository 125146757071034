import * as React from "react";
import { Switch, Route } from "react-router-dom";

import * as PATH from "core/consts/path";
import splitRoute from "./splitRoute";
import QnaCont from "containers/qnaCont";

export default () => (
  <Switch>
    {/* 질문과 답변 */}
    <Route
      path={`${PATH.QNA}/:service/:encodedAnswers?/:loginAfter?`}
      component={QnaCont}
    />

    {/* 견적 요청 완료 */}
    <Route
      path={PATH.ESTIMATION_REQ_COMPLETE}
      component={splitRoute(() => import("containers/estimationComplateCont"))}
    />

    {/* 견적서 */}
    <Route
      path={PATH.ESTIMATION}
      component={splitRoute(() => import("containers/estimationCont"))}
    />

    {/* 자동차 보험 */}
    <Route
      path={PATH.INSOURANCE}
      component={splitRoute(() => import("containers/insuranceCont"))}
    />

    {/* 배터리 */}
    <Route
      path={PATH.BATTERY}
      component={splitRoute(() => import("containers/batteryCont"))}
    />

    {/* 약관 */}
    <Route
      path={PATH.AGREEMENT_CONNECT}
      component={splitRoute(() => import("pages/static/agreementPageConnect"))}
    />
    <Route
      path={PATH.AGREEMENT}
      component={splitRoute(() => import("pages/static/agreementPage"))}
    />

    {/* Insurance companies list with direct signup links */}
    <Route
      path={PATH.INSURANCE_COMPANIES_LIST}
      component={splitRoute(
        () =>
          import("containers/insuranceCompaniesList/InsuranceCompaniesList"),
      )}
    />
  </Switch>
);
