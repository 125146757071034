import * as React from "react";
import Radio from "elements/form/radio";
import Button from "elements/form/button";
import Modal from "elements/helper/modal";
import styled, { css } from "styles/styled";

import { getUserkey } from "core/functions/userkey";

import { postRequestAgreement } from "core/api/estimation";

interface IProps {
  action: string;
  onClose: () => void;
  handleLinkToAgreement: any;
  agreement: boolean;
  handleToggleAgreement: () => void;
  setAgreement: (toggle: boolean) => void;
  setAgreementCompleted: (toggle: boolean) => void;
  navigateToNext: () => void;
  triggerAgreeAndClickThrough: () => void;
}

const StyledComponent = styled.div<{
  agreement: boolean;
}>`
  ${({ agreement, theme: { colors } }) => css`
    .agreementModal {
      &__header {
        font-size: 18px;
        font-weight: bold;
        color: ${colors.nero};
        margin-bottom: 16px;
      }
      &__label {
        border-radius: 16px;
        background-color: ${colors.gray300};
        padding: 16px 16px 12px;
        position: relative;
        margin-bottom: 16px;
        font-weight: bold;
        color: ${colors.nobel};
      }
      &__link {
        text-decoration: underline;
        position: absolute;
        right: 5%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: ${colors.nobel};
      }
      &__summary {
        font-size: 14px;
        font-weight: 300;
        color: ${colors.blackPanther};
        text-align: center;
        margin-bottom: 32px;
        &--bold {
          font-weight: 600;
        }
      }
      &__button {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        border-radius: 8px;
        height: 56px;
        background-color: ${agreement ? `${colors.black}` : `${colors.gray300}`};
        color: ${agreement ? `${colors.white}` : `${colors.silver}`};
      }
    }
  `}
`;

const { useState, useEffect } = React;

const AgreementModal: React.FC<IProps> = ({
  action,
  onClose,
  handleLinkToAgreement,
  agreement,
  setAgreement,
  setAgreementCompleted,
  handleToggleAgreement,
  navigateToNext,
  triggerAgreeAndClickThrough,
}) => {
  const checkIfCanCloseModal = () => {
    const userkey = getUserkey();
    postRequestAgreement(userkey);
    if (agreement) {
      onClose();
      setAgreementCompleted(true);
      // Trigger agreeAndClickThrough Amplitude event
      triggerAgreeAndClickThrough();
      navigateToNext();
    }
  };

  const [isRadioLabelBold, toggleRadioLabelBold] = useState(false);

  useEffect(() => {
    if (agreement) {
      toggleRadioLabelBold(true);
    } else {
      toggleRadioLabelBold(false);
    }
  }, [toggleRadioLabelBold, agreement]);

  return (
    <Modal
      modalType="agreementModal"
      isShow={action === "agreementModal"}
      onClose={checkIfCanCloseModal}
    >
      <StyledComponent agreement={agreement}>
        <div className="agreementModal__header">서비스 동의</div>
        <div className="agreementModal__label">
          <Radio
            checked={agreement}
            label="서비스 약관 및 개인정보 처리 동의 (필수)"
            onClick={handleToggleAgreement}
            boldLabel={isRadioLabelBold}
          />
          <span
            className="agreementModal__link"
            onClick={handleLinkToAgreement}
          >
            보기
          </span>
        </div>
        <div className="agreementModal__summary">
          입력하신 정보는 암호화되어 안전하게 저장되며,
          <br />
          <span className="agreementModal__summary agreementModal__summary--bold">
            보험료 조회를 위해서만 사용
          </span>
          됩니다.
        </div>
        <Button
          className="agreementModal__button"
          color={agreement ? "dark" : "gray"}
          disabled={!agreement}
          onClick={checkIfCanCloseModal}
        >
          동의하고 진행하기
        </Button>
      </StyledComponent>
    </Modal>
  );
};

export default AgreementModal;
