import React from "react";
import { useRecoilState } from "recoil";
import modalMetaAtom from "recoil/atoms/modalMetaAtom";

import styled, { css } from "styled-components";

const StyledModalContainer = styled.div`
  ${({ theme: { myCarV5Colors } }) => {
    return css`
      background-color: ${myCarV5Colors.v5_halfModalDim};
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      ${"" /* Prevent body scroll when modal is open. */}
      touch-action: none;
      -webkit-overflow-scrolling: none;
      overflow: hidden;
      overscroll-behavior: none;
    `;
  }}
`;

const ModalContainer = () => {
  const [modalMeta, setModalMeta] = useRecoilState(modalMetaAtom);

  const handleClick = () => {
    setModalMeta({
      children: null,
    });
  };

  return (
    <>
      {modalMeta.children && (
        <StyledModalContainer onClick={handleClick}>
          {modalMeta.children}
        </StyledModalContainer>
      )}
    </>
  );
};

export default ModalContainer;
