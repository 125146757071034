export const numberFormat = (number: string | number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const telNumberFormat = (number: string) => {
  return number.replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    "$1-$2-$3",
  );
};

export const indentifierFormat = (number: string) => {
  // let substrNumbr = number.substring(0, 13);
  // if (substrNumbr.length === 13) {
  //   substrNumbr = substrNumbr.replace(/(^[0-9]{6})([0-9]{7})/, '$1-$2');
  // }
  // if (substrNumbr.length === 10) {
  //   substrNumbr = substrNumbr.replace(
  //     /(^[0-9]{3})([0-9]{2})([0-9]{5})/,
  //     '$1-$2-$3',
  //   );
  // }

  // if (number.length > 7) {
  //   substrNumbr = substrNumbr.replace(/.$/, '*');
  // }
  return number;
};
