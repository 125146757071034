import * as React from "react";
import styled from "styles/styled";

interface IProps {
  type?: "svg" | "png";
  size?: string | number;
  icon: string;
}

const { useState, useEffect, useCallback } = React;
const StyledComponent = styled.i`
  min-width: 14px;
  min-height: 14px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
`;

const Icon: React.FC<IProps> = ({ type = "svg", size, icon }) => {
  const [state, setState] = useState<{
    icon: string | null;
    isMounted: boolean;
  }>({
    icon: null,
    isMounted: true,
  });
  const defaultIcon =
    "data:image/svg+xml;base64,PHN2ZwogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICB3aWR0aD0iNjIiCiAgaGVpZ2h0PSI2MiIKICB2aWV3Qm94PSIwIDAgNjIgNjIiCj4KICA8cGF0aAogICAgZmlsbD0iIzk5OSIKICAgIGZpbGwtcnVsZT0iZXZlbm9kZCIKICAgIGQ9Ik0zOC4yMzIgMzUuMTc3bC03LjIzNCA0LjE3OC03LjIzNC00LjE3OHYtOC4zNTRsNy4yMzQtNC4xNzggNy4yMzQgNC4xNzh2OC4zNTR6TTMwLjk5OCAxNkMyMi43MTUgMTYgMTYgMjIuNzE2IDE2IDMxYzAgOC4yODQgNi43MTUgMTUgMTQuOTk4IDE1czE0Ljk5Ny02LjcxNiAxNC45OTctMTVjMC04LjI4NC02LjcxNC0xNS0xNC45OTctMTV6IgogIC8+Cjwvc3ZnPgo=";

  const getImagePath = useCallback(() => {
    return new Promise((resolve, reject) => {
      import(
        `./${type}s/${icon.toLocaleLowerCase().replace(" ", "_")}.${type}`
      ).then(
        res => {
          resolve(res.default);
        },
        () => {
          reject(defaultIcon);
        },
      );
    });
  }, [type, icon]);

  useEffect(() => {
    (async () => {
      try {
        const nextIconPath = (await getImagePath()) as string;
        if (state.isMounted) {
          setState({
            ...state,
            icon: nextIconPath,
          });
        }
      } catch (error) {
        setState({
          ...state,
          icon: defaultIcon,
        });
      }
    })();

    return () => {
      setState({
        ...state,
        isMounted: false,
      });
    };
  }, [getImagePath]);

  return !!state.icon ? (
    <StyledComponent
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${state.icon})`,
      }}
    />
  ) : null;
};

export default Icon;
