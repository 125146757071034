import { atom } from "recoil";

interface calculateEstimationType {
  possibleEstimationDatePage: any;
  errorMessageText: any;
  calculationResult: any;
  calculableDate: any;
  commonDetailParam: any;
  logCodefInsuranceSmsId: any;
  calculableDateAfterUserMonthClick: any;
  ceInsuranceDetails: any;
  carrier: any;
  mobileNum: any;
  userName: any;
  ceRequestId: any;
}

const calculateEstimationAtom = atom({
  key: "calculateEstimationState",
  default: {} as calculateEstimationType,
});

export default calculateEstimationAtom;
