import { createGlobalStyle } from "styled-components";
import resetCss from "./reset";
import theme from "./theme";

export default createGlobalStyle`
  ${resetCss}

  * {
    box-sizing: border-box;
    word-break:keep-all;
    outline:none;
  }

  html {
    font-size: ${theme.sizes.baseMargin};
  }

  body {
    // galaxy z fold 접힌 상태( 280px )대응을 위함.
    min-width: 280px;
    height: 100vh;
  }

  body,
  input,
  textarea,
  button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 1rem;
    line-height: 1.3;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
