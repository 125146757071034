import * as React from "react";

interface State {
  authuser: any;
  mycar: any;
}

const initialState: State = {
  authuser: null,
  mycar: null,
};

const { useContext, useState, createContext } = React;
const AppContext = createContext<{
  state: State;
  setState: any;
}>({
  state: initialState,
  setState: null,
});

const AppProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export default AppProvider;
