import * as React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import produce from "immer";
import queryString from "query-string";

import { DrCleverFullScreen } from "components/loading/DrCleverFullScreen";
import { CalculationLoading } from "components/loading/CalculationLoading";

import {
  QNA,
  ESTIMATION_REQ_COMPLETE,
  AGREEMENT_CONNECT,
} from "core/consts/path";
import {
  showPossibleEstimateDatePage,
  showCalculationComplete,
  showCalculationLoading,
  inputAnswerEvent,
  completeInsurance,
  estimateRequestRequiresSMSVerification,
  userSubmitsInsuranceRenewalMonth,
  agreeAndClickThrough,
  uniqueViewOfSelectCoverage,
  calculationErrorEvent,
} from "core/eventMatrics/action";
import { CategoryIds } from "core/interface/connectCategories";
import { useAppContext } from "core/context/appContext";
import {
  createEstimationReq,
  getCardocUserInfo,
  getCodefSession,
  requestSMSVerificationNumberFromCodef,
  confirmSMSVerificationNumber,
  requestEstimationWithUserInputMonth,
} from "core/api/estimation";
import { resetWebviewHistory, toast, goLogin } from "core/appLink/global";
import { openWebview } from "core/appLink/deepLink";
import { getUserkey } from "core/functions/userkey";
import questions from "core/questions";
import QnaPage from "pages/qna/qnaPage";
import DescriptionModal from "components/qna/descriptionModal";
import AgreementModal from "components/qna/AgreementModal/AgreementModal";
import RequestInsuranceEstimationNotificationModal from "components/qna/RequestInsuranceEstimationNotificationModal/RequestInsuranceEstimationNotificationModal";
import PreventNewUserModal from "components/qna/PreventNewUserModal/PreventNewUserModal";
import LoginModal from "components/base/loginModal";
import { IState, IContProps } from "./interface";

import Loading from "elements/helper/loading";

import { useRecoilState } from "recoil";
import modalMetaAtom from "recoil/atoms/modalMetaAtom";
import HalfModal from "components/Modals/HalfModal";

import insuranceQnaStateAtom from "recoil/atoms/insuranceQnaStateAtom";
import calculateEstimationAtom from "recoil/atoms/calculateEstimationAtom";
import topNavbarAtom from "recoil/atoms/topNavbarAtom";
import { AxiosError } from "axios";

const { useState, useEffect, useMemo, useCallback } = React;

const QnaCont: React.FC = () => {
  useEffect(() => {
    setModalTypeToShow("preventNewUserModal");
  }, []);

  const [insuranceQnaState, setInsuranceQnaState] = useRecoilState(
    insuranceQnaStateAtom,
  );
  const [calculateEstimation, setCalculateEstimation] = useRecoilState(
    calculateEstimationAtom,
  );

  const [topNavbar, setTopNavbar] = useRecoilState(topNavbarAtom);
  const [modalMeta, setModalMeta] = useRecoilState(modalMetaAtom);

  const history = useHistory();
  const location = useLocation();
  const routeParams = useParams();
  const queryParams = queryString.parse(location.search);
  const { service, encodedAnswers, loginAfter } = routeParams as {
    service: keyof typeof CategoryIds;
    encodedAnswers: string;
    loginAfter?: "Y";
  };

  const appCtx = useAppContext();

  const questionItems = questions[service];
  const savedAnswers =
    (() => {
      try {
        return JSON.parse(encodedAnswers);
      } catch {
        // 답변 파싱 실패
      }
    })() || [];

  // 답변의 갯수를 기준으로 현재 step을 계산
  const computedStep = savedAnswers.length + 1;
  const currentStep =
    computedStep > questionItems.length ? questionItems.length : computedStep;

  const [isCalcuatingEstimates, toggleIsCalculatingEstimates] = useState(false);
  const [showErrorPage, toggleShowErrorPage] = useState(false);

  const [showEstimationPossibleDatePage, toggleShowEstimationPossibleDatePage] =
    useState(false);
  const [showCalculatedResultPage, toggleShowCalculatedResultPage] =
    useState(false);

  const [
    redirectToShowPossibleInsuranceQuoteDate,
    toggleRedirectToShowPossibleInsuranceQuoteDate,
  ] = useState(false);
  useEffect(() => {
    if (redirectToShowPossibleInsuranceQuoteDate) {
      resetWebviewHistory(
        `${window.location.origin}/possibleInsuranceQuoteDate`,
      );
    }
  }, [redirectToShowPossibleInsuranceQuoteDate]);

  const [coverageStepEventTriggered, toggleCoverageStepEventTriggered] =
    useState(false);

  // state
  const [state, setState] = useState<IState>({
    pending: false,
    isReady: false,
    // 현재 질문셋에 대한 답변
    answers: [],
    // 모달 액션
    modalAction: "",
    // 로그인 모달 노출 여부
    isShowLoginModal: false,
    // hasAgreed to service
    hasAgreedToService: false,
  });

  useEffect(() => {
    if (currentStep === 2 && !coverageStepEventTriggered) {
      const { encodedAnswers } = routeParams as {
        encodedAnswers: string;
      };
      const ciType = appCtx.state?.mycar?.platenum
        ? "보험갱신"
        : "신규구매차량 보험가입";

      toggleCoverageStepEventTriggered(true);
      uniqueViewOfSelectCoverage(
        {
          ciType,
          ciRange: state.answers[1],
        },
        {
          brand: appCtx.state?.mycar?.brandnameko || "",
          model: appCtx.state?.mycar?.modelName || "",
          year: appCtx.state?.mycar?.year || "",
        },
      );
    }
  }, [currentStep, state.answers]);

  const [integratedUIError, setIntegratedUIError] = useState({
    isValidInsuranceType: "",
    isValidLiabilityType: "",
    isValidMobileNumber: true,
    isValidSocialRegistrationNumber: true,
  });

  // TODO: Refactor out into custom hook.
  // Validate input for errors.
  useEffect(() => {
    const {
      0: insuranceType,
      1: liabilityType,
      3: socialRegistrationNumber,
      4: telephoneNumber,
    } = state.answers;

    const telephoneRegex = /^\d{3}\d{3,4}\d{4}$/;
    const isValidMobileNumber = telephoneRegex.test(telephoneNumber);
    // /(^[0-9]{6})([0-9*]{7})/
    const socialRegex = /(^[0-9]{6})([0-9*]{7})/g;
    const isValidSocialRegistrationNumber = socialRegex.test(
      socialRegistrationNumber,
    );
    const buisnessRegex = /(^[0-9]{3})([0-9]{2})([0-9*]{5})/;
    const isValidBusinessRegistrationNumber =
      buisnessRegex.test(socialRegistrationNumber) &&
      socialRegistrationNumber.length === 10;
    const setTrue =
      isValidSocialRegistrationNumber || isValidBusinessRegistrationNumber;
    // console.log('setTrue: ', setTrue);
    setIntegratedUIError({
      isValidInsuranceType: insuranceType,
      isValidLiabilityType: liabilityType,
      isValidMobileNumber,
      isValidSocialRegistrationNumber: true,
    });
  }, [state.answers, setIntegratedUIError]);

  const [agreement, setAgreement] = useState(true);
  const [agreementCompleted, setAgreementCompleted] = useState(false);
  // 마지막 step
  const lastStep = questionItems.length;
  const [integratedQuestionItems, setIntegratedQuestionItems] = useState(
    questions[service][currentStep - 1].integratedQuestions,
  );

  useEffect(() => {
    let integratedQuestionItems: any =
      questions[service][currentStep - 1].integratedQuestions;
    if (currentStep === lastStep) {
      integratedQuestionItems =
        questions[service][currentStep - 1].integratedQuestions;
    }
    setIntegratedQuestionItems(integratedQuestionItems);
  }, [service, currentStep, lastStep]);

  // 현재 step에 대한 질문 set
  const currentQuestionItem = useMemo(() => {
    return questionItems[currentStep - 1];
  }, [currentStep, questionItems]);

  // questionsToMap was added to support the integrated questions step UI
  const [questionsToMap, setQuestionsToMap] = useState(questions[service]);

  useEffect(() => {
    let questionsToMap = questions[service];
    if (integratedQuestionItems) {
      questionsToMap = [
        ...questions[service].slice(0, 2),
        ...integratedQuestionItems,
      ];
    }
    setQuestionsToMap(questionsToMap);
  }, [currentStep, integratedQuestionItems, service]);

  const [gotQuestions, toggleGotQuestions] = useState(false);
  // 질문 불러오기
  const getQuestions = useCallback(() => {
    const splittedAnswer = Object.values(savedAnswers) || [];
    setState({
      ...state,
      isReady: true,
      // 질문셋에 맞는 답변셋을 생성해둠
      answers: questionsToMap.map((question, index) =>
        /**
         * 답변셋 init
         *
         * 1. 라우터에 저장된 답변 (실제 입력한 답변)
         * 2. 마이카와 연동되는 컬럼이라면 마이카에 저장된 정보
         * 3. authUser와 연동되는 컬럼이라면 authUser에 저장된 정보
         * 4. 각 질문의 default value
         *
         * 의 순서로 초기화
         */
        {
          const targetQuestion = questionsToMap[index];

          // mycar 객체에서 뽑아올 데이터
          const mycarData = (() => {
            if (!!targetQuestion?.mycarColumn) {
              return appCtx.state.mycar?.[targetQuestion.mycarColumn];
            } else {
              return null;
            }
          })();

          // authuser 객체에서 뽑아올 데이터
          const authUserData = (() => {
            if (!!targetQuestion?.authUserColumn) {
              const authUserValue =
                appCtx.state.authuser?.[targetQuestion.authUserColumn];

              if (!authUserValue) {
                return null;
              }

              if (["mobilenum"].includes(targetQuestion.authUserColumn)) {
                // 여기에 해당하는 컬럼은 숫자만 추출해서 사용한다.
                return authUserValue.replace(/[^0-9]/g, "");
              } else {
                return authUserValue;
              }
            } else {
              return null;
            }
          })();

          return (
            splittedAnswer?.[index] ||
            mycarData ||
            authUserData ||
            targetQuestion?.defaultValue ||
            ""
          );
        },
      ),
    });
    toggleGotQuestions(true);
  }, [queryParams, appCtx, state, questionsToMap, savedAnswers]);

  const [modalTypeToShow, setModalTypeToShow] = useState("");

  const handleModal = (modalAction: any) => {
    setState({
      ...state,
      modalAction,
    });
  };

  useEffect(() => {
    handleModal(modalTypeToShow);
    if (modalTypeToShow === "showPossibleDateToReceiveInsuranceEstimation") {
      triggerCompleteInsuranceEstimationRequest("modal", null);
      toggleWaitingForResponseType("");
      toggleIsProcessingVerification(false);
    }
  }, [modalTypeToShow]);

  // 로그인 모달 토글
  const handleCloseLoginModal = useCallback(() => {
    setState({
      ...state,
      isShowLoginModal: false,
    });
  }, [state]);

  // 답변 입력
  const handleInput = useCallback(
    (answer, type) => {
      let indexToUpdate = currentStep - 1;
      if (type === "identifier") {
        indexToUpdate += 1;
        if (answer.length === 13) {
          document.getElementById("identifier")?.blur();
          const closeModal = () => {
            setModalMeta({
              children: null,
            });
          };
          setModalMeta({
            children: (
              <HalfModal
                closeModal={closeModal}
                modalMeta={modalMeta}
                setModalMeta={setModalMeta}
              />
            ),
          });
        }
      } else if (type === "telNumber") {
        indexToUpdate += 2;
        if (answer.length === 11) {
          document.getElementById("telNumber")?.blur();
        }
      }
      setState(
        produce(state, draft => {
          draft.answers[indexToUpdate] = answer;
        }),
      );
    },
    [currentStep, state],
  );

  const parseConnectApiMinimalAPIRequestParams = () => {
    const mycarColumns = state.answers.reduce(
      (columns: { [key: string]: string }, answer: string, index: number) => {
        // 해당 인덱스의 질문이 마이카 정보와 매핑되어 있는 컬럼 인지
        const mycarColumn = questionItems[index]?.mycarColumn;
        if (!!mycarColumn) {
          columns[mycarColumn] = answer;
        }
        return columns;
      },
      {},
    );
    return {
      socialRegistrationNumber: state.answers[3],
      telephoneNumber: state.answers[4],
      isNew: state.answers[0] === "신규구매차량 보험가입",
      userCarId: parseInt(appCtx.state.mycar.userCarId, 10),
      ownerName: mycarColumns.ownerName,
    };
  };

  const estimationInquiriesFromQuestionsToMap = () => {
    return questionsToMap.map(({ question }, index) => {
      let indexToSave = index;
      let answerToReturn = "";
      if (index === 4) {
        console.log("4 state.answers[index]: ", state.answers[index]);
        indexToSave = 3;
      } else if (index === 3) {
        indexToSave = 4;
        console.log("3 state.answers[index]: ", state.answers[index]);

        const { userTelecom: userTelecomState, unmaskedIdentifier } =
          insuranceQnaState;
        answerToReturn = unmaskedIdentifier;
        console.log("unmaskedIdentifier: ", unmaskedIdentifier);
      } else if (index === 0) {
        const backupLiability = appCtx.state?.mycar?.platenum
          ? "보험갱신"
          : "신규구매차량 보험가입";
        answerToReturn = backupLiability;
      }
      return {
        type: `q${indexToSave + 1}`,
        question,
        answer: answerToReturn || state.answers[index],
      };
    });
  };
  const [userTelecom, setUserTelecom] = useState(0);

  const [savedUserTelecom, setSavedUserTelecom] = useState(0);
  useEffect(() => {
    if (savedAnswers.length === 6) {
      setSavedUserTelecom(savedAnswers[5]);
    }
  }, [savedAnswers]);

  // 견적 요청서 생성
  const requestEstimation = async () => {
    const userkey = getUserkey();
    if (!userkey) return false;

    const {
      socialRegistrationNumber,
      telephoneNumber,
      isNew,
      userCarId,
      ownerName,
    } = parseConnectApiMinimalAPIRequestParams();
    let telecomToPass = savedUserTelecom || userTelecom;

    const { userTelecom: userTelecomState, unmaskedIdentifier } =
      insuranceQnaState;
    const koreaTelecomArray = [
      "SKT",
      "KT",
      "LG U+",
      "알뜰폰(SKT)",
      "알뜰폰(KT)",
      "알뜰폰(LG U+)",
    ];
    const indexOfUserTelecomState = koreaTelecomArray.indexOf(userTelecomState);

    const liablityType = state.answers[1];
    let liablityTypeNumber = liablityType === "프리미엄 담보" ? "2" : "1";
    try {
      const response = await createEstimationReq({
        estimationInquiries: estimationInquiriesFromQuestionsToMap(),

        coverageType: liablityTypeNumber,

        cdServiceTypeId: CategoryIds[service],
        userCarId,
        authuserkey: userkey,
        ownerName,
        ownerMobileNum: telephoneNumber,
        // telecom: (telecomToPass - 1).toString(),
        telecom: indexOfUserTelecomState.toString(),
        // ownerIdentifier: socialRegistrationNumber,
        ownerIdentifier: unmaskedIdentifier,
        isNew,
        detailParam: codefSession,
      });

      return response;
    } catch (e) {
      return false;
    }
  };

  // 약관 동의
  const handleToggleAgreement = useCallback(() => {
    setAgreement(!agreement);
  }, [agreement]);

  // 약관 보기 페이지로 이동
  const handleLinkToAgreement = useCallback(() => {
    openWebview(`${window.location.origin}${AGREEMENT_CONNECT}`, {
      titlebaryn: "N",
    });
  }, []);

  const [carInsuranceRegistrationMeta, setCarInsuranceRegistrationMeta] =
    useState({
      question: "",
      description: "",
    });

  useEffect(() => {
    if (carInsuranceRegistrationMeta?.question === "보험 가입 월 선택") {
      triggerUserSubmitsInsuranceRenewalMonth();
    }
  }, [carInsuranceRegistrationMeta?.question]);

  const [userSelectedMonth, setUserSelectedMonth] = useState("");

  const [isVerifyingSms, toggleIsVerifyingSms] = useState(false);
  const [waitingForResponseType, toggleWaitingForResponseType] = useState("");

  useEffect(() => {
    if (showEstimationPossibleDatePage) {
      toggleWaitingForResponseType("");
    }
  }, [showEstimationPossibleDatePage]);

  const [isProcessingVerification, toggleIsProcessingVerification] =
    useState(false);
  const [
    hasRecoverableSMSVerificationError,
    toggleHasRecoverableSMSVerificationError,
  ] = useState(false);

  const [SMSVerificationObject, setSMSVerificationObject] = useState({});

  const [possibleDateToRenewInsurance, setPossibleDateToRenewInsurance] =
    useState("");

  const requestSMSVerificationNumber = async (type?: string) => {
    if (type === "fireAnalyticsEvent") {
      triggerEstimateRequestRequiresSMSVerification();
    }

    const userkey = getUserkey();
    if (!userkey) return false;

    const {
      socialRegistrationNumber,
      telephoneNumber,
      isNew,
      userCarId,
      ownerName,
    } = parseConnectApiMinimalAPIRequestParams();

    setState({
      ...state,
      pending: false,
    });

    toggleIsVerifyingSms(true);
    let telecomToPass = savedUserTelecom || userTelecom;

    const { userTelecom: userTelecomState, unmaskedIdentifier } =
      insuranceQnaState;
    const koreaTelecomArray = [
      "SKT",
      "KT",
      "LG U+",
      "알뜰폰(SKT)",
      "알뜰폰(KT)",
      "알뜰폰(LG U+)",
    ];
    const indexOfUserTelecomState = koreaTelecomArray.indexOf(userTelecomState);

    const liablityType = state.answers[1];

    let liablityTypeNumber = liablityType === "프리미엄 담보" ? "2" : "1";

    const response = await requestSMSVerificationNumberFromCodef({
      estimationInquiries: estimationInquiriesFromQuestionsToMap(),

      coverageType: liablityTypeNumber,

      cdServiceTypeId: CategoryIds[service],
      userCarId,
      authuserkey: userkey,
      ownerName,
      ownerMobileNum: telephoneNumber,
      ownerIdentifier: unmaskedIdentifier,
      organization: "0003",
      // telecom: (telecomToPass - 1).toString(),
      timeOut: "169",
      telecom: indexOfUserTelecomState.toString(),
      is2Way: true,
      twoWayInfo: SMSVerificationObject,
      smsAuthNo: SMSInput,
      isNew,
      detailParam: codefSession,
    });
    toggleWaitingForResponseType("");
    return response;
  };

  // Start of functions that handle analytics events.
  const triggerEstimateRequestRequiresSMSVerification = () => {
    estimateRequestRequiresSMSVerification();
  };

  const triggerAgreeAndClickThrough = () => {
    agreeAndClickThrough();
  };

  const triggerUserSubmitsInsuranceRenewalMonth = () => {
    userSubmitsInsuranceRenewalMonth();
  };

  const clearCodefSession = () => {
    setCodefSession("");
    sessionStorage.removeItem("codefSession");
  };

  // 신규 사용자 처리
  const triggerCompleteInsuranceEstimationRequest = (
    type: string,
    response: any,
  ) => {
    console.log(
      "triggerCompleteInsuranceEstimationRequest response: ",
      response,
    );
    completeInsurance(
      {
        ciType: state.answers[0],
        ciRange: state.answers[1],
      },
      {
        brand: appCtx.state?.mycar?.brandnameko || "",
        model: appCtx.state?.mycar?.modelName || "",
        year: appCtx.state?.mycar?.year || "",
      },
      type === "modal" ? "견적예약 알림신청" : "바로 견적요청",
    );
    clearCodefSession();

    if (!(type === "modal")) {
      resetWebviewHistory(window.location.origin + ESTIMATION_REQ_COMPLETE);
    }
  };
  // End of functions that handle analytics events.

  // 답변 입력 완료
  const processResAccountEndDateFromResponse = (
    resAccountEndDate: string,
    type?: string,
  ) => {
    let daysToSubtract = 14;
    if (type === "user-provided-renewal-date") {
      daysToSubtract = 14;
    }
    let date = new Date(resAccountEndDate);
    date.setDate(date.getDate() - daysToSubtract);
    let isoDate = date.toISOString().split("T")[0];
    let dateToString = `${isoDate.slice(0, 4)}년 ${isoDate.slice(
      5,
      7,
    )}월 ${isoDate.slice(8, 10)}일`;
    setPossibleDateToRenewInsurance(dateToString);
  };

  const processRequestSMSVerificationNumberSuccess = (response: any) => {
    const { data } = response.data.verificationMeta;
    const { isAllocated: isAllocatedForced } = response.data;
    if (isAllocatedForced) {
      triggerCompleteInsuranceEstimationRequest("not modal", response);
      return;
    }
    const { isAllocated } = data;
    toggleWaitingForResponseType("");
    if (isAllocated) {
      triggerCompleteInsuranceEstimationRequest("not modal", response);
    } else {
      setTimeout(() => {
        document.getElementById("smsVerification")?.focus();
      }, 100);
      const { jobIndex, threadIndex, jti, twoWayTimestamp } = data;
      setSMSVerificationObject({ jobIndex, threadIndex, jti, twoWayTimestamp });
    }
  };

  const processRequestSMSVerificationNumberFail = (error: any) => {
    toggleIsVerifyingSms(false);
    toggleWaitingForResponseType("");
    const { response } = error;
    if (response) {
      const { data } = response;
      const { code, status, isAllocated } = data;
      if (isAllocated) {
        triggerCompleteInsuranceEstimationRequest("not modal", response);
      } else if (code === "CF-12200") {
        clearCodefSession();

        setCarInsuranceRegistrationMeta({
          question: "보험 가입 월 선택",
          description:
            "고객님의 가입 정보가 확인이 안되었습니다.<br/>보험 가입 월을 선택 해주시기 바랍니다.",
        });
        setTopNavbar({
          ...topNavbar,
          hideTopNavbar: true,
        });
      } else if (status === 400) {
        toast(
          "본인 인증에 실패했습니다. 정보를 정확히 입력 후 다시 시도해주시기 바랍니다.",
        );
      }
    } else {
      clearCodefSession();

      const currentAnswers: any = [];
      for (let i = 0; i < currentStep; i++) {
        currentAnswers.push(state.answers[i]);
      }
      calculationErrorEvent("인증센터 오류", "코드에프 인증", currentAnswers);
      showError("인증센터에 오류가 발생했어요<br/>잠시 후 다시 시도해주세요");
      return;
    }
    setState({
      ...state,
      pending: false,
    });
  };

  useEffect(() => {
    if (state.isReady) {
      // 로그인 여부
      let urlMeta = '["신규구매차량 보험가입"]';
      if (!appCtx.state.authuser) {
        // 로그인 되지 않았다면 로그인 모달을 띄움
        setState({
          ...state,
          isShowLoginModal: true,
          pending: false,
        });
      } else if (!appCtx.state.mycar) {
        // set as 신차
        const userkey = getUserkey();
        resetWebviewHistory(
          `http://cardoc-front-local.cardoc.co.kr:8012/mycar/new/step1?userkey=${userkey}&from=connect-${service}&returnto=connect`,
        );
      } else {
        // set as 갱신
        urlMeta = '["보험갱신"]';
        history.push(`${QNA}/insurance/${urlMeta}`);
      }
    }
  }, [state.isReady]);

  const [SMSInput, setSMSInput] = useState("");
  const [codefSession, setCodefSession] = useState("");

  useEffect(() => {
    const previousCodefSession = window.sessionStorage.getItem("codefSession");
    if (!previousCodefSession) {
      const getPromise = getCodefSession();
      getPromise
        .then(response => {
          const { commDetailParam } = response.data.data;
          window.sessionStorage.setItem("codefSession", commDetailParam);
          setCodefSession(commDetailParam);
        })
        .catch(getCodefSessionError => {
          console.log("getCodefSessionResponse error: ", getCodefSessionError);
        });
    } else {
      setCodefSession(previousCodefSession);
    }
    if (gotQuestions && currentStep === 3) {
      const asyncGetCardocUser = async () => {
        try {
          const getCardocUserInfoResult = await getCardocUserInfo(
            appCtx.state.mycar.userCarId,
          );
          let { carrier, mobileNum, ssnFront, userName } =
            getCardocUserInfoResult.data;
          const telecomMap = [
            "SKT",
            "KT",
            "LG U+",
            "알뜰폰(SKT)",
            "알뜰폰(KT)",
            "알뜰폰(LG U+)",
          ];
          setInsuranceQnaState({
            ...insuranceQnaState,
            userTelecom: telecomMap[carrier],
          });
          setCalculateEstimation({
            ...calculateEstimation,
            carrier,
            mobileNum,
            userName,
          });
          let newAnswers = state.answers;
          newAnswers = [...newAnswers.slice(0, 2), userName, "", mobileNum];
          let newState = {
            ...state,
            answers: [...newAnswers],
          };
          setState(newState);
        } catch (error) {
          console.log("getPromise error: ", (error as AxiosError).response);
        }
      };
      asyncGetCardocUser();
    }
  }, [gotQuestions, currentStep]);

  const [showLoading, togglShowLoading] = useState(false);
  useEffect(() => {
    if (currentStep === 3) {
      togglShowLoading(true);
      setTimeout(() => {
        togglShowLoading(false);

        // document.getElementById('identifier')?.focus();
      }, 2000);
    } else if (currentStep === 2) {
      toggleWaitingForResponseType("");
      toggleIsProcessingVerification(false);
      toggleIsVerifyingSms(false);
      toggleHasRecoverableSMSVerificationError(false);
    }
  }, [currentStep]);

  const possibleEstimationDatePage = (calculableDate: string, type: string) => {
    let calculableDateAfterUserMonthClick = false;
    if (type === "after month click") {
      calculableDateAfterUserMonthClick = true;
    }
    setCalculateEstimation({
      ...calculateEstimation,
      calculableDate,
      calculableDateAfterUserMonthClick,
      possibleEstimationDatePage: true,
    });
    toggleIsVerifyingSms(false);
    toggleShowEstimationPossibleDatePage(true);
    setTopNavbar({
      ...topNavbar,
      hideTopNavbar: false,
      showCloseOnlyOnLeft: true,
    });

    setCarInsuranceRegistrationMeta({
      question: "",
      description: "",
    });
    toggleIsProcessingVerification(false);

    toggleWaitingForResponseType("");

    const currentAnswers: any = [];
    for (let i = 0; i < currentStep; i++) {
      currentAnswers.push(state.answers[i]);
    }

    showPossibleEstimateDatePage(type, currentAnswers, {
      brand: appCtx.state?.mycar?.brandnameko || "",
      model: appCtx.state?.mycar?.modelName || "",
      year: appCtx.state?.mycar?.year || "",
    });
  };

  const calculatedResultPage = (ceInsuranceDetails: any, id: any) => {
    toggleIsCalculatingEstimates(false);
    toggleIsVerifyingSms(false);

    setCalculateEstimation({
      ...calculateEstimation,
      ceInsuranceDetails,
      ceRequestId: id,
    });
    toggleShowCalculatedResultPage(true);

    const currentAnswers: any = [];
    for (let i = 0; i < currentStep; i++) {
      currentAnswers.push(state.answers[i]);
    }

    showCalculationComplete(currentAnswers, {
      brand: appCtx.state?.mycar?.brandnameko || "",
      model: appCtx.state?.mycar?.modelName || "",
      year: appCtx.state?.mycar?.year || "",
    });

    setTopNavbar({
      ...topNavbar,
      hideTopNavbar: true,
    });

    setCarInsuranceRegistrationMeta({
      question: "",
      description: "",
    });
    toggleIsProcessingVerification(false);
    toggleWaitingForResponseType("");
  };

  const showError = (errorText: string) => {
    toggleShowErrorPage(true);
    setTopNavbar({
      ...topNavbar,
      hideTopNavbar: true,
    });

    setCalculateEstimation({
      ...calculateEstimation,
      errorMessageText: errorText,
    });
    toggleWaitingForResponseType("");
    toggleIsProcessingVerification(false);
    toggleIsVerifyingSms(false);
  };

  // 다음 단계로
  const handleSubmit = async () => {
    // NOTE: submit user selected month

    if (userSelectedMonth) {
      setTopNavbar({
        ...topNavbar,
        hideTopNavbar: false,
      });
      let {
        calculationResult,
        calculableDate,
        commonDetailParam,
        logCodefInsuranceSmsId,
      } = calculateEstimation;
      toggleWaitingForResponseType("finalizingEstimation");
      try {
        const userkey = getUserkey();
        if (!userkey) return false;

        const {
          socialRegistrationNumber,
          telephoneNumber,
          isNew,
          userCarId,
          ownerName,
        } = parseConnectApiMinimalAPIRequestParams();
        let telecomToPass = savedUserTelecom || userTelecom;

        const { userTelecom: userTelecomState, unmaskedIdentifier } =
          insuranceQnaState;
        const koreaTelecomArray = [
          "SKT",
          "KT",
          "LG U+",
          "알뜰폰(SKT)",
          "알뜰폰(KT)",
          "알뜰폰(LG U+)",
        ];
        const indexOfUserTelecomState =
          koreaTelecomArray.indexOf(userTelecomState);

        const liablityType = state.answers[1];
        console.log("createEstimationReq liablityType: ", liablityType);
        let liablityTypeNumber = liablityType === "프리미엄 담보" ? "2" : "1";

        const response = await requestEstimationWithUserInputMonth({
          estimationInquiries: estimationInquiriesFromQuestionsToMap(),

          coverageType: liablityTypeNumber,

          cdServiceTypeId: CategoryIds[service],
          userCarId,
          authuserkey: userkey,
          ownerName,
          ownerMobileNum: telephoneNumber,
          ownerIdentifier: unmaskedIdentifier,
          selectedMonth: userSelectedMonth,
          isNew,
          // telecom: (telecomToPass - 1).toString(),
          telecom: indexOfUserTelecomState.toString(),

          commonDetailParam,

          logCodefInsuranceSmsId,
        });

        let {
          data: { isAllocated, calculableDate, calculationResult, message },
        } = response;
        if (calculationResult === false) {
          setCarInsuranceRegistrationMeta({
            question: "",
            description: "",
          });
          const currentAnswers: any = [];
          for (let i = 0; i < currentStep; i++) {
            currentAnswers.push(state.answers[i]);
          }
          let code = message.split(",")?.[0]?.slice(6);
          calculationErrorEvent(code, "보험료계산", currentAnswers);
          showError(
            "고객님의 정보를 확인할 수 없어요<br/>차량등록증상 소유자와 입력하신 정보가<br/>일치하는지 확인해주세요",
          );
          return;
        }
        if (isAllocated) {
          setCarInsuranceRegistrationMeta({
            question: "",
            description: "",
          });

          const currentAnswers: any = [];
          for (let i = 0; i < currentStep; i++) {
            currentAnswers.push(state.answers[i]);
          }

          calculationErrorEvent("명의불일치", "소유자동차", currentAnswers);
          showError(
            "고객님의 정보를 확인할 수 없어요<br/>차량등록증상 소유자와 입력하신 정보가<br/>일치하는지 확인해주세요",
          );
          return;
        } else {
          processResAccountEndDateFromResponse(
            calculableDate,
            "user-provided-renewal-date",
          );
          possibleEstimationDatePage(calculableDate, "after month click");
        }
        return;
      } catch (e) {
        console.log(
          "가입월 등록에 실패했습니다. 다시 시도 부탁드립니다 e: ",
          e,
        );
        showError(
          "일시적 오류로 최저가 보험을 찾지 못했어요<br/>다시 한번 시도해 주세요",
        );
        const currentAnswers: any = [];
        for (let i = 0; i < currentStep; i++) {
          currentAnswers.push(state.answers[i]);
        }
        calculationErrorEvent("명의불일치", "소유자동차", currentAnswers);
      }
    } else if (isVerifyingSms) {
      toggleIsProcessingVerification(true);
      try {
        const userkey = getUserkey();
        if (!userkey) return false;

        let {
          socialRegistrationNumber,
          telephoneNumber,
          isNew,
          userCarId,
          ownerName,
        } = parseConnectApiMinimalAPIRequestParams();
        setState({
          ...state,
          pending: true,
        });

        // 5. 400 error (b. and c. below)
        // a. 해당 마이카가 존재하지 않습니다.
        // userCarId = 0
        let telecomToPass = savedUserTelecom || userTelecom;

        const { userTelecom: userTelecomState, unmaskedIdentifier } =
          insuranceQnaState;
        const koreaTelecomArray = [
          "SKT",
          "KT",
          "LG U+",
          "알뜰폰(SKT)",
          "알뜰폰(KT)",
          "알뜰폰(LG U+)",
        ];
        const indexOfUserTelecomState =
          koreaTelecomArray.indexOf(userTelecomState);

        const liablityType = state.answers[1];
        let liablityTypeNumber = liablityType === "프리미엄 담보" ? "2" : "1";

        toggleIsCalculatingEstimates(true);

        const currentAnswers: any = [];
        for (let i = 0; i < currentStep; i++) {
          currentAnswers.push(state.answers[i]);
        }

        showCalculationLoading(service, currentStep, currentAnswers, {
          brand: appCtx.state?.mycar?.brandnameko || "",
          model: appCtx.state?.mycar?.modelName || "",
          year: appCtx.state?.mycar?.year || "",
        });

        const response = await confirmSMSVerificationNumber({
          estimationInquiries: estimationInquiriesFromQuestionsToMap(),

          coverageType: liablityTypeNumber,

          cdServiceTypeId: CategoryIds[service],
          userCarId,
          authuserkey: userkey,
          ownerName,
          ownerMobileNum: telephoneNumber,
          ownerIdentifier: unmaskedIdentifier,
          organization: "0003",
          // telecom: (telecomToPass - 1).toString(),
          telecom: indexOfUserTelecomState.toString(),
          timeOut: "169",
          is2Way: true,
          twoWayInfo: SMSVerificationObject,
          smsAuthNo: SMSInput,
          isNew,
          detailParam: codefSession,
        });
        let {
          isVerified,
          resAccountEndDate,
          isAllocated,
          smsVerificationMeta,
          calculationResult,
          calculableDate,
          commonDetailParam,
          logCodefInsuranceSmsId,
          ceRequest,
          ceInsuranceDetails,
        } = response.data;
        let id = ceRequest?.id;
        setCalculateEstimation({
          ...calculateEstimation,
          calculationResult,
          calculableDate,
          commonDetailParam,
          logCodefInsuranceSmsId,
        });

        let { code } = smsVerificationMeta.result;

        // UI-UX improvement testing
        // ㄱ. force toggleShowEstimationPossibleDatePage(true)
        // calculableDate = '2025-25-25';
        // setCalculateEstimation({
        //   ...calculateEstimation,
        //   calculableDate,
        // });

        // ㄴ. force calculationResult to be true
        // calculationResult = true;

        // if (calculableDate) {
        //   alert('calculableDate');
        //   // toggleShowEstimationPossibleDatePage(true);
        //   possibleEstimationDatePage();
        //   return;
        // } else if (calculationResult) {
        //   alert('show calculated page');
        //   calculatedResultPage();
        //   return;
        // }

        // ㄷ. force calculationResult to be true
        // isVerified = true;
        // resAccountEndDate = 'yyyy-mm-dd';
        // calculationResult = false;

        // NEW integrated verification insurance estimation request UI/UX flow.
        // Leaving in test case conditions for future testing and refactoring etc.
        // TEST comments for when sms verification number is actually requested
        // 6. 신규가입 인 경우, --> 인증번호 입력 화면 이후에 --> 무조건 결과는 견적요청완료 화면
        // isNew = true

        // 7. 보험만료일이 존재하는 경우
        // a. isAllocated: true면 견적요청완료 화면
        // isNew = false;
        // isVerified = true;
        // resAccountEndDate = 'yyyy-mm-dd';
        // isAllocated = true;

        // 7a. continuation
        // ㄴ. force calculationResult to be true
        // calculationResult = true;

        // 7. 보험만료일이 존재하는 경우
        // b. isAllocated false면 '고객님은 xxxx년 xx월 xx일부터 조회가 가능합니다~' 화면 보여주기
        // Modal for this case is not showing.
        // isNew = false;
        // isVerified = true;
        // resAccountEndDate = '1999-05-23';
        // isAllocated = false;

        // 7b. continuation
        // UI-UX improvement testing
        // ㄱ. force toggleShowEstimationPossibleDatePage(true)
        // calculableDate = '2005-15-25';
        // setCalculateEstimation({
        //   ...calculateEstimation,
        //   calculableDate,
        // });

        // 8. 보험만료일이 비어있는 경우 --> 고객이 직접 '월' 선택하는 화면
        // isNew = false
        // isVerified = true
        // resAccountEndDate= null

        // 9. 그 이외 상황 - response code 로 확인
        // a. (response code 가 CF-***** 일 때) --> 고객이 직접 '월' 선택하는 화면
        // isNew = false
        // isVerified = false
        // code = 'CF-*****'

        // 9. 그 이외 상황 - response code 로 확인
        // b. (response code 가 CF-03002 일 때) - API 요청 처리가 정상 진행 중입니다. 추가 정보를 입력하세요.
        // --> toast(`인증에 실패하였습니다. 인증번호를 정확히 확인 후 입력해주세요.`);
        // isNew = false
        // isVerified = false
        // code = 'CF-03002'

        // 9. 그 이외 상황 - response code 로 확인
        // c. (response code 가 CF-02000 일 때) - 데이터 저장에 실패했습니다.
        // --> toast(`인증에 실패하였습니다. 인증번호를 정확히 확인 후 입력해주세요.`);
        // isNew = false
        // isVerified = false
        // code = 'CF-02000'

        // Disabling 'no-lonely-if' enables more flexible (conditions might change later) and
        // clearer conditional statements and block.
        /* eslint-disable-next-line no-lonely-if */

        if (isVerified) {
          if (resAccountEndDate) {
            if (calculationResult === false) {
              if (calculableDate) {
                possibleEstimationDatePage(calculableDate, "");
              } else {
                const currentAnswers: any = [];
                for (let i = 0; i < currentStep; i++) {
                  currentAnswers.push(state.answers[i]);
                }
                let code = response?.data?.message.split(",")?.[0]?.slice(6);
                calculationErrorEvent(code, "보험료계산", currentAnswers);
                showError(
                  "일시적 오류로 최저가 보험을 찾지 못했어요<br/>다시 한번 시도해 주세요",
                );
              }
            } else {
              /* eslint-disable-next-line no-lonely-if */
              if (isAllocated) {
                // 7. 보험만료일이 존재하는 경우
                // a. isAllocated: true면 견적요청완료 화면
                // triggerCompleteInsuranceEstimationRequest('not modal', response);

                // UI-UX improvement testing
                // ㄴ. force calculationResult to be true
                if (calculationResult) {
                  calculatedResultPage(ceInsuranceDetails, id);
                  return;
                }
              } else {
                // 7. 보험만료일이 존재하는 경우
                // b. isAllocated false면 '고객님은 xxxx년 xx월 xx일부터 조회가 가능합니다~' 화면 보여주기

                clearCodefSession();

                // UI-UX improvement testing
                // ㄱ. force toggleShowEstimationPossibleDatePage(true)
                if (calculableDate) {
                  possibleEstimationDatePage(calculableDate, "");
                  return;
                }
              }
            }
          } else {
            // 8. 보험만료일이 비어있는 경우 --> 고객이 직접 '월' 선택하는 화면

            setCarInsuranceRegistrationMeta({
              question: "보험 가입 월 선택",
              description:
                "고객님의 가입 정보가 확인이 안되었습니다.<br/>보험 가입 월을 선택 해주시기 바랍니다.",
            });
            setTopNavbar({
              ...topNavbar,
              hideTopNavbar: true,
            });
            clearCodefSession();
            toggleIsVerifyingSms(false);
            toggleWaitingForResponseType("");
          }
        } else {
          // Disabling 'no-lonely-if' enables more flexible (conditions might change later) and
          // clearer conditional statements and block.
          /* eslint-disable-next-line no-lonely-if */
          if (isNew && isAllocated) {
            if (code === "CF-03002") {
              // 인증 번호가 일치하지 않게 입력 된 사항.
              toast(
                `인증에 실패하였습니다. 인증번호를 정확히 확인 후 입력해주세요.`,
              );
              toggleIsProcessingVerification(false);
              toggleWaitingForResponseType("");
              toggleHasRecoverableSMSVerificationError(true);
            } else {
              // 6. 신규가입 인 경우, --> 인증번호 입력 화면 이후에 --> 무조건 결과는 견적요청완료 화면
              triggerCompleteInsuranceEstimationRequest("not modal", response);
            }
          } else {
            toggleWaitingForResponseType("");
            if (code === "CF-03002") {
              // 9. 그 이외 상황 - response code 로 확인
              // b. (response code 가 CF-03002 일 때) --> toast(`인증에 실패하였습니다. 인증번호를 정확히 확인 후 입력해주세요.`);
              toast(
                `인증에 실패하였습니다. 인증번호를 정확히 확인 후 입력해주세요.`,
              );
              toggleHasRecoverableSMSVerificationError(true);
            } else if (code === "CF-02000") {
              // 9. 그 이외 상황 - response code 로 확인
              // b. (response code 가 CF-02000 일 때) --> toast(`인증에 실패하였습니다. 인증번호를 정확히 확인 후 입력해주세요.`);
              toast(
                `인증에 실패하였습니다. 인증번호를 정확히 확인 후 입력해주세요.`,
              );
              toggleHasRecoverableSMSVerificationError(true);
            } else {
              // Includes "CF-13224" ()
              // 9. 그 이외 상황 - response code 로 확인
              // a. (response code 가 예를 들어서, CF-00000 일 때) --> 고객이 직접 '월' 선택하는 화면
              // TODO: make setCarInsuranceRegistrationMeta modal into function?

              setCarInsuranceRegistrationMeta({
                question: "보험 가입 월 선택",
                description:
                  "고객님의 가입 정보가 확인이 안되었습니다.<br/>보험 가입 월을 선택 해주시기 바랍니다.",
              });
              setTopNavbar({
                ...topNavbar,
                hideTopNavbar: true,
              });
              clearCodefSession();
              toggleIsVerifyingSms(false);
              toggleWaitingForResponseType("");
            }
          }
        }
        toggleIsCalculatingEstimates(false);
      } catch (e) {
        toggleIsCalculatingEstimates(false);
        // change let to const once testing is complete
        const data = (e as any)?.response?.data;

        // 5. 400 error
        // b. 인증 실패한 경우 (인증번호 잘못입력, 시간초과 등..)
        // --> 400에러 (에러메세지 포함)
        // data.message ='응답 대기시간을 초과했습니다.'

        // 5. 400 error
        // c. 보험다모아 api에서 조회된 차량이 없거나 마이카의 차량번호와 일치하는 data가 없을때
        // --> 400에러 (에러메세지: 마이카와 일치하는 차량이 조회되지 않았습니다)
        // data.message = '추가 정보 입력값이 잘못되었습니다. (2way) 확인 후 거래하시기 바랍니다.'

        let toastText = "";
        if (data?.status !== 200) {
          if (
            data?.message.includes(
              "추가 정보 입력값이 잘못되었습니다. (2way) 확인 후 거래하시기 바랍니다.",
            ) ||
            data?.message.includes("code=CF-03002")
          ) {
            // NOTE: when wrong 6 digit sms verification code is input
            toastText = `인증을 성공적으로 하지 못했습니다. 인증 번호를 다시 정확하게 입력해주세요.`;
            toast(toastText);
          } else {
            // NOTE: all other 400 and 500 errors show "당황/미안" error (777777)

            const currentAnswers: any = [];
            for (let i = 0; i < currentStep; i++) {
              currentAnswers.push(state.answers[i]);
            }
            let code = "";
            if (data?.status === 500) {
              code = "내부서버";
            } else {
              code = "코드에프";
            }
            calculationErrorEvent(code, "보험료계산", currentAnswers);
            showError(
              "일시적 오류로 최저가 보험을 찾지 못했어요<br/>다시 한번 시도해 주세요",
            );
          }
        }
        // toast(toastText);
      } finally {
        setState({
          ...state,
          pending: false,
        });
      }
      return;
    }
    let fireAnalyticsEvent = false;
    if (currentStep === 1) {
      fireAnalyticsEvent = true;
      navigateToNext();
    } else if (currentStep === 2) {
      fireAnalyticsEvent = true;
      if (agreementCompleted) {
        navigateToNext();
      } else {
        setModalTypeToShow("agreementModal");
      }
    } else if (currentStep === lastStep) {
      toggleWaitingForResponseType("processingEstimationRequest");
      try {
        const response =
          await requestSMSVerificationNumber("fireAnalyticsEvent");

        processRequestSMSVerificationNumberSuccess(response);
        // alert('0. isNew');
      } catch (error) {
        processRequestSMSVerificationNumberFail(error);
        // alert('0. isNew error');
      }
    }
    if (fireAnalyticsEvent) {
      // 현재 스텝까지의 답변을 URL에 담아서 다음 단계로
      const currentAnswers: any = [];
      for (let i = 0; i < currentStep; i++) {
        currentAnswers.push(state.answers[i]);
      }
      // 다음 단계로 이동시 지표 전송
      inputAnswerEvent(service, currentStep, currentAnswers, {
        brand: appCtx.state?.mycar?.brandnameko || "",
        model: appCtx.state?.mycar?.modelName || "",
        year: appCtx.state?.mycar?.year || "",
      });
    }
  };

  const navigateToNext = () => {
    // 현재 스텝까지의 답변을 URL에 담아서 다음 단계로
    const currentAnswers: any = [];
    for (let i = 0; i < currentStep; i++) {
      currentAnswers.push(state.answers[i]);
    }

    history.push(
      `${QNA}/${service}/${encodeURI(JSON.stringify(currentAnswers))}`,
    );
  };

  // SSO 로그인
  const handleLinkToLogin = () => {
    // loginAfter를 Y로 넘겨서 로그인 후 redirect 됐을 때 바로 견적 요청 완료하도록 한다.
    let answers = [...state.answers, userTelecom];
    let urlMeta = '["신규구매차량 보험가입"]';
    if (appCtx.state.mycar) {
      // set as 갱신
      urlMeta = '["보험갱신"]';
      goLogin(`${window.location.origin + QNA}/insurance/${urlMeta}`);
    } else {
      // set as 신차
      const userkey = getUserkey();
      goLogin(
        `${process.env.REACT_APP_FRONT_HOST}/mycar/new/step1?userkey=${userkey}&from=connect-${service}`,
      );
    }
  };

  // 컴포넌트 마운트 후 질문 불러오기
  useEffect(() => {
    getQuestions();

    if (loginAfter === "Y") {
      // loginAfter가 Y인 경우 바로 견적 요청 완료 처리
      setTimeout(() => {
        document.getElementById("cta-button")?.click();
      }, 100);
    }
  }, [questionsToMap, loginAfter]);

  // 현재 답변이 변경될 떄마다 유효성 검사
  useEffect(() => {
    const {
      0: insuranceType,
      1: liabilityType,
      3: socialRegistrationNumber,
      4: telephoneNumber,
    } = state.answers;
    const telephoneRegex = /^\d{3}\d{3,4}\d{4}$/;
    const isValidMobileNumber = telephoneRegex.test(telephoneNumber);
    const socialRegex =
      /\b(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))[0-9]{7}\b/g;
    const isValidSocialRegistrationNumber = socialRegex.test(
      socialRegistrationNumber,
    );
    const buisnessRegex = /([0-9]{3})-?([0-9]{2})-?([0-9]{5})/;
    const isValidBusinessRegistrationNumber =
      buisnessRegex.test(socialRegistrationNumber) &&
      socialRegistrationNumber.length === 10;
    const setTrue =
      isValidSocialRegistrationNumber || isValidBusinessRegistrationNumber;
    setIntegratedUIError({
      isValidInsuranceType: insuranceType,
      isValidLiabilityType: liabilityType,
      isValidMobileNumber,
      isValidSocialRegistrationNumber: true,
    });
  }, [state.answers]);

  useEffect(() => {
    if (isVerifyingSms) {
      toggleWaitingForResponseType("smsVerification");
      document
        .getElementById("cta-button")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isVerifyingSms]);

  // 질문 단계가 바뀔때마다 실행
  useEffect(() => {
    // SSO 로그인으로 가기 전 약관 동의를 하므로, 로그인 후에 온 경우라면 약관 동의 처리 해둠
    if (loginAfter) {
      setAgreement(true);
      // return;
    }
  }, [loginAfter]);

  if (!state.isReady || !currentQuestionItem) {
    return null;
  }

  const dataSource: IContProps = {
    showErrorPage,
    showEstimationPossibleDatePage,
    showCalculatedResultPage,
    pending: state.pending,
    mycar: appCtx.state.mycar,
    lastStep,
    step: currentStep,
    answer: state.answers[currentStep - 1],
    answers: state.answers,
    agreement,
    agreementMessage: currentQuestionItem?.agreementMessage || "",
    // TODO: delete isError prop if not used
    isError: false,
    questionItem: currentQuestionItem,
    integratedQuestionItems,
    onModal: handleModal,
    onInput: handleInput,
    onSubmit: handleSubmit,
    onToggleAgreement: handleToggleAgreement,
    onLinkToAgreement: handleLinkToAgreement,
    userSelectedMonth,
    setUserSelectedMonth,
  };
  return (
    <>
      {showLoading ? (
        <DrCleverFullScreen isLoading={showLoading} opaque />
      ) : (
        <>
          {/* 질문과 답변 페이지 */}
          <QnaPage
            integratedUIError={integratedUIError}
            carInsuranceRegistrationMeta={carInsuranceRegistrationMeta}
            {...dataSource}
            isVerifyingSms={isVerifyingSms}
            toggleIsVerifyingSms={toggleIsVerifyingSms}
            hasRecoverableSMSVerificationError={
              hasRecoverableSMSVerificationError
            }
            isProcessingVerification={isProcessingVerification}
            SMSVerificationObject={SMSVerificationObject}
            SMSInput={SMSInput}
            setSMSInput={setSMSInput}
            userTelecom={savedUserTelecom || userTelecom}
            setUserTelecom={setUserTelecom}
            userSelectedMonth={userSelectedMonth}
            setUserSelectedMonth={setUserSelectedMonth}
            requestSMSVerificationNumber={requestSMSVerificationNumber}
            processRequestSMSVerificationNumberSuccess={
              processRequestSMSVerificationNumberSuccess
            }
            toggleWaitingForResponseType={toggleWaitingForResponseType}
          />

          {/* 설명 문구 노출용 모달 */}
          <DescriptionModal
            action={state.modalAction}
            onClose={() => {
              setState({ ...state, modalAction: "" });
            }}
          />
          {/* 설명 문구 노출용 모달 */}
          <AgreementModal
            action={state.modalAction}
            onClose={() => {
              setState({ ...state, modalAction: "", hasAgreedToService: true });
            }}
            // Comment on what this "agreement" means.
            agreement={agreement}
            setAgreement={setAgreement}
            setAgreementCompleted={setAgreementCompleted}
            handleLinkToAgreement={handleLinkToAgreement}
            // This makes the legacy Radio agreement work
            handleToggleAgreement={handleToggleAgreement}
            navigateToNext={navigateToNext}
            triggerAgreeAndClickThrough={triggerAgreeAndClickThrough}
          />

          {/* 설명 문구 노출용 모달 */}
          <RequestInsuranceEstimationNotificationModal
            action={state.modalAction}
            onClose={() => {
              setState({ ...state, modalAction: "", pending: false });
            }}
            possibleDateToRenewInsurance={possibleDateToRenewInsurance}
            userSelectedMonth={userSelectedMonth}
          />

          {/* 로그인 모달 */}
          <LoginModal
            isShow={state.isShowLoginModal}
            onLinkToLogin={handleLinkToLogin}
            onClose={handleCloseLoginModal}
          />

          {/* Prevent new users without plate number from entering service */}
          <PreventNewUserModal action={state.modalAction} />
          {waitingForResponseType && (
            <Loading
              isShow={!!waitingForResponseType}
              waitingForResponseType={waitingForResponseType}
            />
          )}
          {isCalcuatingEstimates && <CalculationLoading opaque />}
        </>
      )}
    </>
  );
};

export default QnaCont;
