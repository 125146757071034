import * as React from "react";
import styled, { css } from "styles/styled";

interface IProps {
  className?: string;
  title: string | JSX.Element;
  type?: string;
  isIntegratedError?: boolean;
  value?: string;
  userTelecom?: number;
}

const StyledComponent = styled.dl<{
  type?: string;
  isIntegratedError?: boolean;
  value?: string;
  userTelecom?: number;
}>`
  ${props => {
    const {
      type,
      theme: { colors },
    } = props;
    if (type) {
      const bubbleTypes = [
        "string",
        "identifier",
        "telNumber",
        "userTelecom",
        "smsVerification",
      ];
      const indexOfType = bubbleTypes.indexOf(type);
      if (indexOfType > -1) {
        return css`
          height: 72px;
          border-radius: 16px;
          border: solid 1px ${colors.whisper};
          padding: 12px 16px 13px;
          margin-bottom: 8px;
          &:focus-within {
            box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.05);
            border: solid 1px ${colors.black};
          }
        `;
      }
    }
  }}
  ${({ isIntegratedError, type, userTelecom, value, theme: { colors } }) => {
    const validationError =
      type !== "userTelecom" && isIntegratedError && value;
    if (validationError) {
      return css`
          box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.05);
          border: solid 1px ${colors.cardocRed};
          &:focus-within{
            box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.05);
            border: solid 1px ${colors.cardocRed};
          }
          .row-form {
            &__title {
              font-size: 12px;
              color: ${colors.cardocRed};
              height: 18px;
              margin: 0 0 2px 0;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
            }
        `;
    }
  }}
  ${({ value, type, theme: { colors } }) => css`
    .row-form {
      &__title {
        height: 18px;
        margin: 0 0 2px 0;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: ${colors.nobel};
      }

      &__content {
        margin-top: ${value || type === "userTelecom" ? "0" : "10px"};
      }
    }
  `}
`;

const RowForm: React.FC<IProps> = ({
  className,
  title,
  children,
  type,
  isIntegratedError,
  value,
  userTelecom,
}) => {
  return (
    <StyledComponent
      className={className}
      type={type}
      isIntegratedError={isIntegratedError}
      value={value}
      userTelecom={userTelecom}
    >
      {(type === "my-car-row-form" || type === "userTelecom" || value) && (
        <dt className="row-form__title">
          {type === "userTelecom" ? "통신사" : title}
        </dt>
      )}
      <dd className="row-form__content">{children}</dd>
    </StyledComponent>
  );
};

export default RowForm;
