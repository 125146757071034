import { atom } from "recoil";

interface iModalMeta {
  children: JSX.Element | null;
}

const modalMetaAtom = atom({
  key: "modalMeta",
  default: <iModalMeta>{
    children: null,
  },
});

export default modalMetaAtom;
