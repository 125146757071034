import { callAppInterface } from "core/appLink/global";

interface Mycar {
  brand: string;
  model: string;
  year: string;
}

export const clickShowEstimateNow = () => {
  callAppInterface("sendAmplitudeEvent", {
    name: "Click INS Box in Complete Page",
  });
};

export const userInsuranceRenewalMonthClicked = () => {
  callAppInterface("sendAmplitudeEvent", {
    name: "Click Select INS Month",
  });
};

export const enterEstimationList = () => {
  callAppInterface("sendAmplitudeEvent", {
    name: "View INS Estimate Box_Web",
  });
};

export const uniqueViewOfSelectCoverage = (
  params: {
    ciType: string;
    ciRange: string;
  },
  mycar: Mycar,
) => {
  const payload = {
    name: "View Selecting INS Cover Range Screen_Connect_Web",
    params: {
      "CI Type": params.ciType,
      Brandname: mycar.brand,
      Modelname: mycar.model,
      Year: mycar.year,
    },
  };
  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendBrazeEvent", payload);
};

export const clickedOnDirectInsuranceSignupLink = (params: any) => {
  const {
    partnerName,
    insuranceCompanyName,
    insuranceAmount: insuranceCost,
    indexClickedPlusOne: nthEstimationClicked,
    totalEstimationsRecieved,
  } = params;
  callAppInterface("sendAmplitudeEvent", {
    name: "Click CI Company Website_Connect_Web",
    params: {
      partnerName,
      insuranceCompanyName,
      insuranceCost,
      nthEstimationClicked,
      totalEstimationsRecieved,
    },
  });
};

export const clickedOnLeavePartnerReview = () => {
  callAppInterface("sendAmplitudeEvent", {
    name: "Click CI Write Review_Connect_Web",
  });
};

export const clickedOnViewPartnerReview = () => {
  callAppInterface("sendAmplitudeEvent", {
    name: "Click CI View Review_Connect_Web",
  });
};

export const clickedOnSignupCTAButton = (params: any) => {
  const { partnerName, totalEstimationsRecieved } = params;
  callAppInterface("sendAmplitudeEvent", {
    name: "Click CI Chat_Estimate CTA_Web",
    params: {
      partnerName,
      totalEstimationsRecieved,
    },
  });
};

export const agreeAndClickThrough = () => {
  callAppInterface("sendAmplitudeEvent", {
    name: "Agree on CI Estimates Service",
  });
};

export const estimateRequestRequiresSMSVerification = () => {
  callAppInterface("sendAmplitudeEvent", {
    name: "Start Bohum Damoa API Authentication",
  });
};

export const userSubmitsInsuranceRenewalMonth = () => {
  callAppInterface("sendAmplitudeEvent", {
    name: "Select CI Subscription Month Manually",
  });
};

/**
 * 자동차 보험 견적 요청 시작
 */
export const startInsurance = ({ brand, model, year }: Mycar) => {
  if (brand && model && year) {
    callAppInterface("sendKakaoAdTrackEvent", {
      name: "Participation",
      params: {
        tag: "InsuranceCheck",
      },
    });
  }
  const payload = {
    name: "Click Request CI Estimates",
    params: {
      Brandname: brand,
      Modelname: model,
      Year: year,
    },
  };
  callAppInterface("sendFirebaseEvent", payload);
  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendBrazeEvent", payload);
  callAppInterface("sendAmplitudeEvent", payload);
};

/**
 * 자동차 보험 견적 요청 완료
 */
export const completeInsurance = (
  params: {
    ciType: string;
    ciRange: string;
  },
  mycar: Mycar,
  type?: string,
) => {
  let convertToKakaoEventInsuranceTypeString = "SignUp";

  if (type === "견적예약 알림신청") {
    convertToKakaoEventInsuranceTypeString = "Subscription";
  }

  callAppInterface("sendKakaoAdTrackEvent", {
    name: "SignUp",
    params: {
      tag: convertToKakaoEventInsuranceTypeString,
    },
  });

  const payload = {
    name: "Complete CI Estimates",
    params: {
      "CI Type": params.ciType,
      "CI Cover Range": params.ciRange,
      Brandname: mycar.brand,
      Modelname: mycar.model,
      Year: mycar.year,
      "CI Action Result": type,
    },
  };

  callAppInterface("sendFirebaseEvent", payload);
  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendAmplitudeEvent", payload);
  callAppInterface("sendBrazeEvent", payload);

  callAppInterface("sendBrazeAttribute", {
    name: "Complete CI Estimates",
  });
};

/**
 * 커넥트 뒤로가기
 */
export const backEvent = (service: string, step: number) => {
  if (!service || !step) {
    return;
  }

  // 자동차 보험
  if (service === "insurance") {
    callAppInterface("sendAmplitudeEvent", {
      name: "Back CI Estimates",
      params: {
        BackStep: step,
      },
    });
  }
};

/**
 * 커넥트 닫기
 */
export const closeEvent = (service: string, step: number) => {
  if (!service || !step) {
    return;
  }

  // 자동차 보험
  if (service === "insurance") {
    callAppInterface("sendAmplitudeEvent", {
      name: "Quit CI Estimates",
      params: {
        QuitStep: step,
      },
    });
  }
};

/**
 * 질문 답변 지표
 */
export const inputAnswerEvent = (
  service: string,
  step: number,
  answers: string[],
  mycar: Mycar,
) => {
  if (!service || !step) {
    return;
  }

  const currentAnswer = answers?.[step - 1];

  // 자동차 보험
  if (service === "insurance") {
    if (step === 1) {
      callAppInterface("sendAmplitudeEvent", {
        name: "Select CI New or Renewal",
        params: {
          "CI Type": currentAnswer,
          Brandname: mycar.brand,
          Modelname: mycar.model,
          Year: mycar.year,
        },
      });

      callAppInterface("sendBrazeAttribute", {
        name: "Select CI New or Renewal",
        params: {
          // 'Incomplete CI Type': currentAnswer,
        },
      });
    } else if (step === 2) {
      callAppInterface("sendAmplitudeEvent", {
        name: "Select CI Cover Range",
        params: {
          "CI Type": answers[0],
          "CI Cover Range": currentAnswer,
          Brandname: mycar.brand,
          Modelname: mycar.model,
          Year: mycar.year,
        },
      });

      callAppInterface("sendBrazeAttribute", {
        name: "Select CI Cover Range",
        params: {
          // 'Incomplete CI Cover Range': currentAnswer,
        },
      });
    }
  }
};

export const showCalculationLoading = (
  service: string,
  step: number,
  answers: string[],
  mycar: Mycar,
) => {
  const currentAnswer = answers?.[0];

  const payload = {
    name: "Calculating INS",
    params: {
      "CI Type": currentAnswer,
      "CI Cover Range": answers[1],
      Brandname: mycar.brand,
      Modelname: mycar.model,
      Year: mycar.year,
    },
  };

  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendAmplitudeEvent", payload);
  callAppInterface("sendBrazeEvent", payload);
};

export const showCalculationComplete = (answers: string[], mycar: Mycar) => {
  const currentAnswer = answers?.[0];

  const payload = {
    name: "Complete INS Calculation",
    params: {
      "CI Type": currentAnswer,
      "CI Cover Range": answers[1],
      Brandname: mycar.brand,
      Modelname: mycar.model,
      Year: mycar.year,
    },
  };

  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendAmplitudeEvent", payload);
  callAppInterface("sendBrazeEvent", payload);
};

export const showPossibleEstimateDatePage = (
  type: string,
  answers: string[],
  mycar: Mycar,
) => {
  const currentAnswer = answers?.[0];

  let payload = {
    name: "Reserve INS Alarm",
    params: {
      "Select Calendar": type === "after month click" ? "Y" : "N",
      "CI Type": currentAnswer,
      "CI Cover Range": answers[1],
      Brandname: mycar.brand,
      Modelname: mycar.model,
      Year: mycar.year,
    },
  };

  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendAmplitudeEvent", payload);
  callAppInterface("sendBrazeEvent", payload);
};

export const clickedEngineOilCrossSellingCta = (type: string) => {
  let payload = {
    name: "Click Cross Selling in INS Reservation Page",
    params: {
      "Select Calendar": type === "after month click" ? "Y" : "N",
    },
  };

  callAppInterface("sendAmplitudeEvent", payload);
};

export const clickedCloseAndReturnToHome = (from: string) => {
  let payload = {
    name: "Click Close in INS Reservation Page",
    params: {
      "Select Calendar": from === "from select calendar page" ? "Y" : "N",
    },
  };

  callAppInterface("sendAmplitudeEvent", payload);
};

export const clickedRedirectToHomeFromErrorPage = () => {
  let payload = {
    name: "Click Home in INS Fail Page",
  };

  callAppInterface("sendAmplitudeEvent", payload);
};

export const clickedOnChatFromErrorPage = () => {
  let payload = {
    name: "Click CS in INS Fail Page",
  };

  callAppInterface("sendAmplitudeEvent", payload);
};

export const calculationErrorEvent = (
  errorCode: string,
  errorType: string,
  answers: string[],
) => {
  const currentAnswer = answers?.[0];

  let payload = {
    name: "Fail INS Calculation",
    params: {
      "Error Code": errorCode,
      "Error Type": errorType,
      "CI Type": currentAnswer,
    },
  };

  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendAmplitudeEvent", payload);
  callAppInterface("sendBrazeEvent", payload);
};

export const viewInsuranceCompaniesList = () => {
  const payload = {
    name: "view_ins_company_list",
  };

  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendAmplitudeEvent", payload);
  callAppInterface("sendFirebaseEvent", payload);
  callAppInterface("sendBrazeEvent", payload);
};

export const clickInsuranceCompanyFromList = (params: any) => {
  const {
    insuranceCompanyName,
    nthEstimationClicked,
    bannerType,
    category,
    type,
    promotion,
  } = params;

  const indexOfDirectString = insuranceCompanyName.includes("다이렉트")
    ? insuranceCompanyName.indexOf("다이렉트")
    : insuranceCompanyName.length;

  const insuranceCompanyNameToSend = insuranceCompanyName
    .split("")
    .slice(0, indexOfDirectString)
    .join("");

  const payload = {
    name: "click_ins_join",
    params: {
      insuranceCompanyNameToSend,
      nthEstimationClicked,
      bannerType,
      category,
      type,
      promotion,
    },
  };

  callAppInterface("sendAppsFlyerEvent", payload);
  callAppInterface("sendAmplitudeEvent", payload);
  callAppInterface("sendFirebaseEvent", payload);
  callAppInterface("sendBrazeEvent", payload);
};
