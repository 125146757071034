/* eslint prefer-template: 0 */
import { useState, useEffect } from "react";

export const useDynamicIcon = iconUrl => {
  const [dynamicIcon, setDynamicIcon] = useState(null);
  useEffect(() => {
    const asyncGetIcon = async () => {
      await import("styles/icons/" + iconUrl)
        .then(icon => {
          setDynamicIcon(icon.default);
        })
        .catch(error => {
          console.log("error: ", error);
        });
    };
    if (iconUrl) {
      asyncGetIcon();
    }
  }, [iconUrl]);
  return dynamicIcon;
};

export const useDynamicLottieFile = lottieFileUrl => {
  const [dynamicLottieFile, setDynamicLottieFile] = useState(null);
  useEffect(() => {
    const asyncGetLottieFile = () => {
      import("styles/lottie/" + lottieFileUrl)
        .then(lottieFile => {
          setDynamicLottieFile(lottieFile.default);
        })
        .catch(error => {
          console.log("error: ", error);
        });
    };
    if (lottieFileUrl) {
      asyncGetLottieFile();
    }
  }, [lottieFileUrl]);
  return dynamicLottieFile;
};
