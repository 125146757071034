import { css } from "./styled";

const colors = {
  primary: "#2e96f9",
  secondary: "#272c39",
  border: "#e5e6e8",
  dark: "#272c39",
  red: "#e2574c",
  gray000: "#f6fafd",
  gray100: "#fafafa",
  gray200: "#f7f7f8",
  gray300: "#f4f4f4",
  gray400: "#e5e6e8",
  gray500: "#ced3d6",
  gray600: "#96a0a8",
  gray700: "#5e7686",
  gray800: "#686e72",
  gray900: "#333340",
  // Named colors
  white: "#ffffff",
  aliceBlue: "#ebf0f2",
  dodgerBlue: "#0068ff",
  cardocRed: "#fd3d30",
  whisper: "#e6e6e6",
  whiteSmoke: "#f8f8f8",
  whiterSmoke: "#f0f0f0",
  nero: "#222222",
  blackPanther: "#424242",
  nobel: "#9e9e9e",
  silver: "#c4c4c4",
  zambezi: "#5c5c5c",
  black: "#000000",
};

const myCarV5Colors = {
  v5_white100: "#ffffff",
  v5_gray050: "#f8f8f8",
  v5_gray100: "#f4f4f4",
  v5_gray200: "#f0f0f0",
  v5_gray300: "#e6e6e6",
  v5_gray400: "#e0e0e0",
  v5_gray500: "#c4c4c4",
  v5_gray600: "#9e9e9e",
  v5_gray700: "#5c5c5c",
  v5_gray800: "#424242",
  v5_gray900: "#222222",
  v5_black100: "#000000",
  v5_blue100: "#0068ff",
  v5_red100: "#fd3d30",
  v5_halfModalDim: "rgba(0, 0, 0, 0.5)",
};

const sizes = {
  baseMargin: "16px",
  minHeight: "500px",
  headerHeight: "65px",
  borderRadius: "2px",
};

const mixins = {
  tableStyles: css`
    width: 100%;

    strong {
      font-weight: bold;
    }

    caption {
      padding-bottom: 15px;
      font-size: 17px;
      font-weight: 600;
      text-align: left;
    }

    th,
    td {
      padding: 10px;
      text-align: center;
    }

    th {
      border-top: 1px solid ${colors.border};
      border-bottom: 1px solid ${colors.border};
      background-color: ${colors.whiteSmoke};
      font-size: 15px;
    }

    td {
      border-bottom: 1px solid ${colors.border};
      font-size: 13px;

      &:first-of-type {
        border-right: 1px solid ${colors.border};
        text-align: left;
      }
    }
  `,
};

export default { colors, sizes, mixins, myCarV5Colors };
