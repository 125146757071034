import * as React from "react";
import * as htmls from "core/questions/htmls";
import Modal from "elements/helper/modal";
import styled, { css } from "styles/styled";

interface IProps {
  action: string;
  onClose: () => void;
}

const StyledComponent = styled.div`
  ${({ theme: { mixins } }) => css`
    table {
      ${mixins.tableStyles}
    }
  `}
`;

const descriptionModal: React.FC<IProps> = ({ action, onClose }) => {
  return (
    <Modal
      modalType="liabilityCoverageComparisonModal"
      isShow={action === "liabilityCoverageComparisonModal"}
      onClose={onClose}
    >
      <StyledComponent
        dangerouslySetInnerHTML={{
          __html: (htmls as { [key: string]: string })[action],
        }}
      />
    </Modal>
  );
};

export default descriptionModal;
