import { atom } from "recoil";

const topNavbarAtom = atom({
  key: "topNavbar",
  default: {
    hideTopNavbar: false,
    showCloseOnlyOnLeft: false,
  },
});

export default topNavbarAtom;
