import * as React from "react";
import styled from "styles/styled";
import theme from "styles/theme";

const StyledComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: white;
  font-size: 14px;
  color: ${theme.colors.gray700};
`;

const Loading: React.FC = ({ children }) => (
  <StyledComponent>{children}</StyledComponent>
);

export default Loading;
