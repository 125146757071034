import _ from "lodash";
import querystring from "querystring";
import toastComponent from "elements/helper/toast";

export const callAppInterface = (action: string, data: string | object) => {
  const jsonStr = _.isObject(data) ? JSON.stringify(data) : data;
  const typedWindow = window as any;

  console.log(`callAppInterface\naction: ${action}\ndata: `, data);

  try {
    if (typedWindow.webkit && typedWindow.webkit.messageHandlers) {
      // iOS
      if (
        typedWindow.webkit.messageHandlers[action] &&
        typedWindow.webkit.messageHandlers[action].postMessage
      ) {
        typedWindow.webkit.messageHandlers[action].postMessage(jsonStr);
      }
    } else if (typedWindow.CardocAndroid) {
      // Android
      if (typedWindow.CardocAndroid[action]) {
        typedWindow.CardocAndroid[action](jsonStr);
      }
    }
  } catch (e) {
    console.error(`App 인터페이스 함수 호출 실패 (${action})`);
  }
};

/**
 * 인앱 여부
 */
export const isInApp = () => {
  return !!(window as any).app;
};

/**
 * 앱버전 확인
 */
export const getAppInfo = (funcName = "") => {
  callAppInterface("getAppInfo", funcName);
};

/**
 * 뒤로가기
 */
export const goBack = () => {
  callAppInterface("goBack", {});
};

/**
 * 웹뷰 닫기
 */
export const closeWebview = (refrashHome = false, cardoc_url = "") => {
  const params = {
    refresh_home_yn: refrashHome ? "Y" : "N",
    cardoc_url: !!cardoc_url ? cardoc_url : null,
  };
  window.location.href = `cardoc://close_webview?${querystring.stringify(
    _.pickBy(params, _.identity),
  )}`;
};

/**
 * 웹뷰 초기화
 */
export const resetWebviewHistory = (url: string) => {
  window.location.href = isInApp()
    ? `cardoc://reset_webview_history?start_url=${encodeURIComponent(url)}`
    : url;
};

/**
 * 토스트 메세지
 */
export const toast = (message: string, type?: string) => {
  if (isInApp()) {
    callAppInterface("showToast", message);
  } else {
    toastComponent.show(message);
  }
};

/**
 * 이용 내역
 */
export const myRequestHistory = () => {
  window.location.href = "cardoc://goto_tab?tab=myrequests";
};

/**
 * 운영팀과 상담
 */
export const chatWithCardoc = () => {
  window.location.href = "cardoc://chatwithcardoc";
};

/**
 * SSO 로그인
 */
export const goLogin = (redirectUrl: string) => {
  window.location.href = isInApp()
    ? `${process.env.REACT_APP_LOGIN_HOST}?success_url=${encodeURIComponent(
        redirectUrl,
      )}&titlebar_hidden=Y&is_member_only=Y`
    : `${process.env.REACT_APP_LOGIN_HOST}?redirect_url=${encodeURIComponent(
        redirectUrl,
      )}&is_member_only=Y`;
};

// Copied from techshop/src/modules/appLink.js
// 외부 브라우저 열기
// cardoc://open_browser?url={encoded_url}
export const openBrowser = (url: string) => {
  const params = querystring.stringify({
    url,
  });
  if (isInApp()) {
    window.location.href = `cardoc://open_browser?${params}`;
  } else {
    window.location.href = url;
  }
};
