import queryString from "query-string";
import _ from "lodash";
import { isInApp } from "./global";

const deepLink = (link: string, params?: object) => {
  const filterdParams = _.pickBy(params);
  const requestParams = _.isEmpty(filterdParams)
    ? ""
    : `?${queryString.stringify(filterdParams)}`;

  window.location.href = `cardoc://${link + requestParams}`;
};

export const openWebview = (
  url: string,
  queryParams?: {
    title?: string;
    navigationyn?: "Y" | "N";
    titlebaryn?: "Y" | "N";
    modalyn?: "Y" | "N";
    singletopyn?: "Y" | "N";
    linkmode?: "external" | "internal";
  },
) => {
  if (isInApp()) {
    deepLink("open_webview", {
      url,
      ...queryParams,
    });
  } else {
    window.location.href = url;
  }
};

export const openBrowser = (url: string) => {
  if (isInApp()) {
    deepLink("open_browser", {
      url,
    });
  } else {
    window.location.href = url;
  }
};

export default deepLink;
