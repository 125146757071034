/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MyCarButton from "components/buttons/MyCarButton";

import styled, { css } from "styled-components";

import { useRecoilValue } from "recoil";
import insuranceQnaStateAtom from "recoil/atoms/insuranceQnaStateAtom";

const StyledCancelAndConfirmButtons = styled.div`
  ${({ theme: { myCarV5Colors } }) => css`
    text-align: center;
    padding-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
      font-size: 16px;
      font-weight: bold;
      border-radius: 8px;
      width: 100%;
      padding: 16px 0;
    }

    .left-button-area {
      flex: 1;
      margin-right: 8px;
      #modal-left-cancel-button {
        color: ${myCarV5Colors.v5_black100};
        background-color: ${myCarV5Colors.v5_gray200};
      }
    }

    .right-button-area {
      flex: 1;
      #modal-right-confirm-button {
        background-color: ${myCarV5Colors.v5_black100};
        color: ${myCarV5Colors.v5_white100};
        &:disabled {
          color: ${myCarV5Colors.v5_gray500};
          background-color: ${myCarV5Colors.v5_gray100};
        }
      }
    }
  `}
`;

const CancelAndConfirmButtons = props => {
  const insuranceQnaState = useRecoilValue(insuranceQnaStateAtom);

  const {
    cancelText,
    confirmText,
    onCancel,
    onConfirm,

    modalMeta,
  } = props;
  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  const [disableConfirmButton, toggleDisableConfirmButton] = useState(false);

  useEffect(() => {
    if (insuranceQnaState.userTelecom) {
      toggleDisableConfirmButton(false);
    } else {
      toggleDisableConfirmButton(true);
    }
  }, [insuranceQnaState]);

  return (
    <StyledCancelAndConfirmButtons>
      {!onCancel && (
        <div className="left-button-area" onClick={handleCancel}>
          <MyCarButton id="modal-left-cancel-button" text={cancelText} />
        </div>
      )}
      <div className="right-button-area" onClick={handleConfirm}>
        <MyCarButton
          disableButton={disableConfirmButton}
          id="modal-right-confirm-button"
          text={confirmText}
        />
      </div>
    </StyledCancelAndConfirmButtons>
  );
};

export default CancelAndConfirmButtons;

CancelAndConfirmButtons.propTypes = {
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
