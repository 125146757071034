import * as React from "react";
import styled, { css } from "styles/styled";
import Radio from "elements/form/radio";
import Desc from "elements/helper/desc";

interface IProps {
  id: string;
  name: string;
  label: string;
  value?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: React.ChangeEvent) => void;
}

const StyledComponent = styled.label`
  ${({ theme: { colors } }) => css`
    input {
      display: none;

      &:checked {
        + .check-item__label {
          font-size: 18px;
          font-weight: bold;
          color: ${colors.nero};

          .check-item__label__default {
            display: none;
          }

          .check-item__label__active {
            display: block;
          }
        }

        + .check-item__label + .check-item__content {
          height: auto;
          opacity: 1;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.5;
          color: ${colors.blackPanther};
        }
      }
    }

    .check-item {
      &__label {
        height: 44px;
        display: flex;
        align-items: center;
        position: relative;
        font-size: 18px;
        font-weight: regular;
        color: ${colors.nero};

        &__default,
        &__active {
          margin-left: auto;
        }

        &__active {
          display: none;
        }
      }

      &__content {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 500ms ease;
      }
    }
  `}
`;

const CheckItem: React.FC<IProps> = ({ label, id, children, ...attrs }) => (
  <StyledComponent>
    <input type="radio" id={id} {...attrs} />
    <div className="check-item__label">
      {label}
      <span className="check-item__label__default">
        <Radio checked={false} />
      </span>
      <span className="check-item__label__active">
        <Radio checked />
      </span>
    </div>
    {children && (
      <div className="check-item__content">
        <Desc>{children}</Desc>
      </div>
    )}
  </StyledComponent>
);

export default CheckItem;
