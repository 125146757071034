/**
 * 질문과 답변
 */
export const QNA = "/qna";

/**
 * 견적
 */

export const ESTIMATION = "/estimation";

// 견적 요청 완료
export const ESTIMATION_REQ_COMPLETE = `${ESTIMATION}/complete`;

// 견적함
export const ESTIMATION_LIST = `${ESTIMATION}/list`;

// 견적 요청내역
export const ESTIMATION_DETAIL = `${ESTIMATION}/detail`;

/**
 * 자동차 보험
 */
export const INSOURANCE = "/insurance";

/**
 * 배터리
 */
export const BATTERY = "/battery";

/**
 * 약관
 */
export const AGREEMENT_CONNECT = "/agreement-connect";
export const AGREEMENT = "/agreement";

/**
 * Insurance companies with direct signup links
 */
export const INSURANCE_COMPANIES_LIST = "/insurance-companies-list";
