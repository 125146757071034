import {
  connectApiCreator,
  connectApiCreatorWithUserkey,
} from "core/api/apiCreator";

/**
 * 견적 요청서 생성
 */
interface ICreateEstimationReqParams {
  estimationInquiries: object;
  coverageType?: string;
  cdServiceTypeId: number;
  userCarId: number;
  authuserkey: string;
  latitude?: number;
  longitude?: number;
  ownerName?: string;
  ownerIdentifier?: string;
  ownerMobileNum?: string;
  organization?: string;
  telecom?: string;
  timeOut?: string;
  smsAuthNo?: string;
  is2Way?: boolean;
  twoWayInfo?: {
    jobIndex?: number;
    threadIndex?: number;
    jti?: string;
    twoWayTimestamp?: number;
  };
  isNew: boolean;
  detailParam: string;
}

interface IReqParamsUserMonth {
  estimationInquiries?: object;
  coverageType?: string;
  cdServiceTypeId?: number;
  userCarId?: number;
  authuserkey?: string;
  ownerName?: string;
  ownerIdentifier?: string;
  ownerMobileNum?: string;
  organization?: string;
  selectedMonth?: string;
  isNew: boolean;
  telecom?: string;

  commonDetailParam?: any;
  logCodefInsuranceSmsId?: any;
}

interface IReqSMSVerificationParams {
  ownerName: string;
  ownerIdentifier: string;
  ownerMobileNum: string;
  organization: string;
  telecom: string;
  timeOut: string;
  detailParam: string;
}

export const postRequestAgreement = (userkey: any) => {
  return connectApiCreatorWithUserkey.post("/api/v1/request/agreement", {
    authuserkey: userkey,
  });
};

export const getCardocUserInfo = (userCarId: string) => {
  const getCardocUserInfoResponse = connectApiCreator.get(
    `/api/v1/request/user-info?userCarId=${userCarId}`,
  );
  return getCardocUserInfoResponse;
};

export const getCodefSession = () => {
  const requestSMSVerificationNumberFromCodefResponse =
    connectApiCreatorWithUserkey.get("/api/v1/request/codef/session");
  return requestSMSVerificationNumberFromCodefResponse;
};

export const createEstimationReq = (params: ICreateEstimationReqParams) => {
  return connectApiCreatorWithUserkey.post(
    "/api/v1/request/verification",
    params,
  );
};

export const requestSMSVerificationNumberFromCodef = (
  params: ICreateEstimationReqParams,
) => {
  const requestSMSVerificationNumberFromCodefResponse =
    connectApiCreatorWithUserkey.post(
      "/api/v1/request/verification/codef",
      params,
    );
  return requestSMSVerificationNumberFromCodefResponse;
};

export const confirmSMSVerificationNumber = (
  params: ICreateEstimationReqParams,
) => {
  return connectApiCreatorWithUserkey.post(
    "/api/v1/request/verification/sms",
    params,
  );
};

export const requestEstimationWithUserInputMonth = (
  params: IReqParamsUserMonth,
) => {
  return connectApiCreatorWithUserkey.post("/api/v1/request/manual", params);
};

/**
 * 견적서 조회
 */
export const getEstimation = (requestId: number) =>
  // TODO: [CONNECT-367] get list of estimations to show from lowest to highest
  connectApiCreator(`/api/v1/request/${requestId}`);

/**
 * 견적서 읽음 처리
 */
export const readEstimation = (estId: number) =>
  connectApiCreator.put(`/api/v1/request/estimationId/${estId}/update/readYn`);

/**
 * Get details for insurance companies list
 */
export const getInsuranceCompaniesList = () =>
  connectApiCreator.get(`/api/v1/insurance`);
