import * as React from "react";
import styled, { css } from "styles/styled";

interface IProps {
  className?: string;
  t?: number;
  b?: number;
  l?: number;
  r?: number;
}

const StyledComponent = styled.div<{
  t?: number;
  b?: number;
  l?: number;
  r?: number;
}>`
  ${({ t, b, l, r }) => css`
    ${
      typeof t === "number" &&
      css`
        padding-top: ${t}px;
      `
    }
    ${
      typeof b === "number" &&
      css`
        padding-bottom: ${b}px;
      `
    }
    ${
      typeof l === "number" &&
      css`
        padding-left: ${l}px;
      `
    }
    ${
      typeof r === "number" &&
      css`
        padding-right: ${r}px;
      `
    }
  `}
`;

const section: React.FC<IProps> = ({ className, t, b, l, r, children }) => (
  <StyledComponent className={className} t={t} b={b} l={l} r={r}>
    {children}
  </StyledComponent>
);

export default section;
