import * as React from "react";
import classnames from "classnames";
import styled, { css } from "styles/styled";
import { toast } from "core/appLink/global";

import Select from "elements/form/select";
import { useDynamicIcon } from "hooks/useDynamicMedia";

import { useRecoilValue, useRecoilState } from "recoil";

import modalMetaAtom from "recoil/atoms/modalMetaAtom";
import insuranceQnaStateAtom from "recoil/atoms/insuranceQnaStateAtom";
import HalfModal from "components/Modals/HalfModal";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  errorMessage?: string;
  value?: string;
  label?: string;
  defaultValue?: string;
  userTelecom?: number;
  setUserTelecom?: (telecom: number) => void;
  requestSMSVerificationNumber?: () => void;
  processRequestSMSVerificationNumberSuccess?: (response: any) => void;
  toggleWaitingForResponseType?: (text: string) => void;
  ref?: any;
}

const StyledComponent = styled.div<{
  isError: boolean;
  type: string | undefined;
  secondsToDisplay: number;
  userTelecom: number | undefined;
  showRerequestSMS: number;
}>`
  ${({
    type,
    isError,
    secondsToDisplay,
    showRerequestSMS,
    theme: { colors },
  }) => css`
    .input {
      &__box {
        width: 100%;
        padding: 2px 0 8px;
        border: none;
        border-radius: 0;
        font-size: 18px;
        line-height: 18px;

        height: 27px;
        margin: 2px 0 0;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: normal;
        border-bottom: ${
          type === "plateNumber"
            ? `1px solid ${isError ? colors.red : colors.border}`
            : "none"
        };
        &::placeholder {
          height: 27px;
          font-size: 18px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: ${colors.whisper};
        }
      }
      &__error-message {
        margin-top: 5px;
        font-size: 13px;
        color: ${colors.red};
      }
    }

    .absolute-position-select{
      border: none;
      position: absolute;
      top: 6px;
      z-index: 10;
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 18px;
      letter-spacing: normal;
      text-align-last: left;
    }
    .relative-container{
      position: relative;
      .arrow-icon{
        ${
          type === "userTelecom" &&
          `
            position: absolute;
            right: 10px;
          `
        };
      }
    }
    .row-input-relative{
      position: relative;
    }
    .absolute-position-sms-timer-and-rerequest{
      position: absolute;
      right: 3%;
      padding-top 5px;
    }
    .re-request-link {
      display: ${secondsToDisplay > showRerequestSMS ? "none" : "display"};
      padding-left: 8px;
      margin-left: 8px;
      height: 17px;
      font-size: 14px;
    }
    .sms-verification-countdown-timer{
      color: ${
        secondsToDisplay > showRerequestSMS
          ? colors.dodgerBlue
          : colors.cardocRed
      };
      height: 17px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: -8px;
        top: 3px; 
        height: 12px;
        width: ${secondsToDisplay > showRerequestSMS ? "0" : "1px"};
        background-color: ${colors.whisper};
      }
    }
  `}
`;

const { useState, useEffect, forwardRef } = React;

const SMS_VERIFICATION_TIMEOUT_IN_SECONDS = 180;
const SHOW_RE_REQUEST_SMS_IN_SECONDS = 59;

const Input: React.FC<IProps> = forwardRef((props, ref) => {
  const [insuranceQnaState, setInsuranceQnaState] = useRecoilState(
    insuranceQnaStateAtom,
  );
  // console.log('input insuranceQnaState: ', insuranceQnaState);
  const { userTelecom: userTelecomState } = insuranceQnaState;
  const [modalMeta, setModalMeta] = useRecoilState(modalMetaAtom);

  const arrowIcon = useDynamicIcon("ico-dropdown-24-black.svg");

  const closeModal = () => {
    setModalMeta({
      children: null,
    });
  };
  const handleTelecomClick = (e: any) => {
    if (!insuranceQnaState.userTelecom) {
      console.log("setting insuranceQnaState");
      console.log("insuranceQnaState: ", insuranceQnaState);
      // setInsuranceQnaState({
      //   ...insuranceQnaState,
      //   userTelecom: 'SKT',
      // });
    }
    setModalMeta({
      children: (
        <HalfModal
          closeModal={closeModal}
          modalMeta={modalMeta}
          setModalMeta={setModalMeta}
        />
      ),
    });
  };
  const {
    className,
    errorMessage,
    userTelecom,
    setUserTelecom,
    requestSMSVerificationNumber,
    processRequestSMSVerificationNumberSuccess,
    label,
    toggleWaitingForResponseType,
    ...attrs
  } = props;
  // console.log('input userTelecom: ', userTelecom);
  const onTelecomSelectChange = (e: any) => {
    const { value } = e.target;
    if (setUserTelecom) {
      setUserTelecom(value);
    }
  };

  const reRequestSMS = async () => {
    if (
      requestSMSVerificationNumber &&
      toggleWaitingForResponseType &&
      processRequestSMSVerificationNumberSuccess
    ) {
      try {
        toggleWaitingForResponseType("rerequestSMSVerificationNumberFromCodef");
        setSecondsToDisplay(SMS_VERIFICATION_TIMEOUT_IN_SECONDS);
        const response = await requestSMSVerificationNumber();
        processRequestSMSVerificationNumberSuccess(response);
        toggleWaitingForResponseType("");
      } catch (e) {
        toggleWaitingForResponseType("");
        console.log("reRequestSMS error: ", e);
        toast(
          `SMS 인증이 중복적으로 요청 되었습니다. 잠시 후 다시 시도해주세요.`,
        );
      }
    }
  };
  let attrsToPass = attrs;
  const isFieldWithContent = attrs.value;
  const { type } = attrs;
  // console.log('input attrs: ', attrs);
  let newValue = "";
  const { id, value } = attrs;
  if (id === "identifier") {
    if (value && value.length > 7) {
      newValue = value.replace(/.$/, "*");
      attrsToPass = {
        ...attrsToPass,
        value: newValue,
      };
    }
  }
  if (id === "telNumber" || id === "identifier" || type === "smsVerification") {
    attrsToPass = {
      ...attrsToPass,
      type: "tel",
    };
  }

  const [secondsToDisplay, setSecondsToDisplay] = useState(
    SMS_VERIFICATION_TIMEOUT_IN_SECONDS,
  );
  useEffect(() => {
    if (type === "smsVerification") {
      const timer = setTimeout(() => {
        setSecondsToDisplay(secondsToDisplay - 1);
      }, 1000);

      if (secondsToDisplay === 0) {
        clearTimeout(timer);
      }
      return () => clearTimeout(timer);
    }
  }, [type, secondsToDisplay]);

  const convertSecondsIntoMMSS = (seconds: number) => {
    const secondsToDisplay = `0${seconds % 60}`.toString().slice(-2);
    const minutesToDisplay = `0${Math.floor(seconds / 60)}`
      .toString()
      .slice(-2);
    const timeToDisplay = `${minutesToDisplay}:${secondsToDisplay}`;
    return timeToDisplay;
  };
  const [inputKeyinType, setinputKeyinType] = useState("");

  useEffect(() => {
    if (
      type === "identifier" ||
      type === "telNumber" ||
      type === "smsVerification"
    ) {
      setinputKeyinType("[0-9]*");
    }
  }, [type]);

  const koreaTelecomArray = [
    "SKT",
    "KT",
    "LG U+",
    "알뜰폰(SKT)",
    "알뜰폰(KT)",
    "알뜰폰(LG U+)",
  ];

  const handleClick = (e: any) => {
    // console.log('e: ', e);
    // e.target.focus();
  };

  return (
    <StyledComponent
      type={type}
      className={classnames(className, "input")}
      isError={!!errorMessage}
      secondsToDisplay={secondsToDisplay}
      userTelecom={userTelecom}
      showRerequestSMS={SHOW_RE_REQUEST_SMS_IN_SECONDS}
    >
      <div className="relative-container">
        {type === "userTelecom" ? (
          <>
            <input
              ref={ref}
              {...attrsToPass}
              onClick={handleTelecomClick}
              readOnly
              placeholder="통신사"
              value={userTelecomState || "통신사"}
              className={classnames(className, "input__box")}
              pattern={inputKeyinType}
            />
            {arrowIcon && (
              <img
                src={arrowIcon}
                className="arrow-icon"
                alt="arrow-icon"
                onClick={handleTelecomClick}
              />
            )}
            {/* <Select
              className="absolute-position-select"
              defaultValue={userTelecom || 0}
              onChange={onTelecomSelectChange}
            >
              <option disabled value={0}>
                통신사
              </option>
              {koreaTelecomArray.map((value, i) => (
                <option key={i + 1} value={i + 1}>
                  {value}
                </option>
              ))}
            </Select> */}
          </>
        ) : (
          <>
            <input
              ref={ref}
              {...attrsToPass}
              onClick={handleClick}
              placeholder={label}
              className={classnames(className, "input__box")}
              pattern={inputKeyinType}
            />
            {(!isFieldWithContent || errorMessage) &&
              type === "plateNumber" && (
                <div className="input__error-message">{errorMessage}</div>
              )}
            {type === "smsVerification" && (
              <span className="absolute-position-sms-timer-and-rerequest">
                <span className="sms-verification-countdown-timer">
                  {convertSecondsIntoMMSS(secondsToDisplay)}
                </span>
                <span className="re-request-link" onClick={reRequestSMS}>
                  재요청
                </span>
              </span>
            )}
          </>
        )}
      </div>
    </StyledComponent>
  );
});

export default Input;
