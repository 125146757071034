import * as React from "react";
import styled, { css } from "styles/styled";
import Icon from "elements/helper/icon";

interface IProps {
  isShow: boolean;
  waitingForResponseType?: string;
}

const { useState, useEffect } = React;

const StyledComponent = styled.div<{
  waitingForResponseType: string | undefined;
}>`

  ${({ theme: { colors } }) => {
    return css`
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 9999;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.3);

      i {
        animation: rotation 1.6s linear infinite;

        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(-360deg);
          }
        }
      }
      .loading-text{
        margin-top: 24px;
        height: 48px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #5c5c5c;
      }
    `;
  }}
  ${({ waitingForResponseType }) => {
    if (waitingForResponseType) {
      return css`
      i {
        animation: rotation .6s linear infinite;
      }
        background-color: rgba(255, 255, 255, 1);
        opacity: 0.95;
      `;
    }
  }}
`;

const Loading: React.FC<IProps> = ({ isShow, waitingForResponseType }) => {
  const [textToShow, setTextToShow] = useState("");

  useEffect(() => {
    if (waitingForResponseType === "smsVerification") {
      setTextToShow("정확한 견적을 위한<br/>인증번호 요청 중");
    } else if (
      waitingForResponseType === "rerequestSMSVerificationNumberFromCodef"
    ) {
      setTextToShow("정확한 견적을 위한<br/>인증번호 재요청 중");
    } else if (waitingForResponseType === "finalizingEstimation") {
      setTextToShow("보험 전문가에게<br/>정보 전달 중");
    } else if (waitingForResponseType === "processingEstimationRequest") {
      setTextToShow("보험 견적 요청<br/>처리 중");
    } else {
      setTextToShow("");
    }
  }, [waitingForResponseType]);

  useEffect(() => {
    if (
      waitingForResponseType === "smsVerification" ||
      waitingForResponseType === "rerequestSMSVerificationNumberFromCodef"
    ) {
      const timer = setTimeout(() => {
        if (textToShow === "빠르게 처리 중입니다<br />조금만 기다려 주세요") {
          setTextToShow("정확한 견적을 위한<br/>인증번호 요청 중");
        } else {
          setTextToShow("빠르게 처리 중입니다<br />조금만 기다려 주세요");
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [waitingForResponseType, textToShow]);
  return isShow ? (
    <StyledComponent waitingForResponseType={waitingForResponseType}>
      <Icon type="png" size="55px" icon="loading-indicator" />
      {textToShow && (
        <div
          className="loading-text"
          dangerouslySetInnerHTML={{ __html: textToShow }}
        />
      )}
    </StyledComponent>
  ) : null;
};

export default Loading;
