import * as React from "react";
import styled, { css } from "styles/styled";

interface IProps {}

const Component = styled.div`
  ${({ theme: { colors } }) => css`
    padding: 20px 20px;
    background-color: ${colors.gray300};
    border-radius: 16px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.38;

    strong {
      font-weight: 600;
    }

    p {
      line-height: 1.4;
      font-weight: 300;

      :first-of-type {
        margin-top: 2px;
      }

      &:before {
        content: '-';
      }
    }
  `}
`;

const Desc: React.FC<IProps> = ({ children }) => {
  return <Component>{children}</Component>;
};

export default Desc;
