/* eslint-disable */
import React from "react";

import styled, { css } from "styled-components";

const StyledMyCarButton = styled.div`
  ${({ immediateRightIcon }) => css`
    .text{
        position: relative;
        .immediate-right-icon {
          display: ${immediateRightIcon ? "inherit" : "none"};
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          padding-left: 4px;
        }
      }
    }
  `}
`;

const MyCarButton = props => {
  const { id, className, text, immediateRightIcon, disableButton } = props;
  return (
    <StyledMyCarButton immediateRightIcon={immediateRightIcon}>
      <button disabled={disableButton} id={id} className={className}>
        <span className="text">
          {text}
          <img
            className="immediate-right-icon"
            src={immediateRightIcon}
            alt="button icon"
          />
        </span>
      </button>
    </StyledMyCarButton>
  );
};

export default MyCarButton;
