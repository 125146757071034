/* eslint no-bitwise: 0 */
// NOTE: JS one liners from https://1loc.dev

export const isEmpty = obj => JSON.stringify(obj) === "{}";

export const commaSeparatedNumberString = number => {
  if (number) {
    return number
      .toString()
      .split(/(?=(?:\d{3})+$)/)
      .join(",");
  }
};

export const onlyNumericalInput = input => {
  return input.replace(/\D/g, "");
};

export const stripSingleCharFromString = (string, charToStrip) => {
  return string.split(charToStrip).join("");
};

export const destructDateIntoArray = date =>
  date
    .toISOString()
    .split(/[^0-9]/)
    .slice(0, -1);

export const randomItemFromArray = arr => arr[(Math.random() * arr.length) | 0];
