/* eslint-disable */
import React from "react";

import styled, { css } from "styled-components";

import ScrollPicker from "./ScrollPicker";

const StyledScrollPickerArea = styled.section`
  ${({ theme: { myCarV5Colors } }) => {
    return css`
      margin-bottom: 0;
      padding-bottom: 40px;
      h3 {
        font-size: 16px;
        font-weight: normal;
        color: ${myCarV5Colors.v5_gray600};
        margin-bottom: 0;
        padding-bottom: 32px;
      }
      .scroll-highlighter {
        width: 100%;
        height: 48px;
        border-radius: 8px;
        background-color: ${myCarV5Colors.v5_gray200};
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
    `;
  }}
`;

const ScrollPickerArea = props => {
  console.log("props: ", props);
  const {
    label,
    content: { dataIndex, scrollMeta },
    modalData,
    setModalData,

    contentBackendMeta,

    defaultScrollMeta,
    defaultScrollValue,
  } = props;

  const { scrolls } = scrollMeta;
  return (
    <StyledScrollPickerArea>
      <h3>{label}</h3>
      <div className="scroll-highlighter">
        {scrolls.map(scrollMeta => {
          return (
            <ScrollPicker
              key={`${dataIndex}-${scrollMeta.defaultText}`}
              dataIndex={dataIndex}
              scrollMeta={scrollMeta}
              modalData={modalData}
              setModalData={setModalData}
              contentBackendMeta={contentBackendMeta}
              defaultScrollMeta={defaultScrollMeta}
              defaultScrollValue={defaultScrollValue}
            />
          );
        })}
      </div>
    </StyledScrollPickerArea>
  );
};

export default ScrollPickerArea;
