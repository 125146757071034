import * as React from "react";

import styled, { css } from "styles/styled";

import { userInsuranceRenewalMonthClicked } from "core/eventMatrics/action";
interface IProps {
  userSelectedMonth: string;
  setUserSelectedMonth: (month: string) => void;
}

const StyledComponent = styled.div<{
  currentClickedMonth: number;
}>`
  ${({ currentClickedMonth, theme: { colors } }) => {
    return css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      [class^='select-month-circle'] {
        flex: 1 0 20%;
        margin: 6px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        height: 64px;
        max-width: 64px !important;
      }
      .select-month-circle-${currentClickedMonth} {
        height: 64px;
        max-width: 64px !important;
        background-color: ${colors.gray300};
      }
    `;
  }}
`;

const SelectInsuranceRenewalMonth: React.FC<IProps> = ({
  userSelectedMonth,
  setUserSelectedMonth,
}): any => {
  const handleInsuranceRenewalMonthClick = (month: number) => {
    let twoCharMonth = `0${month}`.slice(-2);
    setUserSelectedMonth(twoCharMonth);
    userInsuranceRenewalMonthClicked();
  };

  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];
  return (
    <StyledComponent
      className="select-month-grid"
      currentClickedMonth={parseInt(userSelectedMonth, 10)}
    >
      {months.map((month: string, index: number) => {
        return (
          <span
            className={`select-month-circle-${index + 1}`}
            onClick={() => handleInsuranceRenewalMonthClick(index + 1)}
            key={index}
          >
            {month}
          </span>
        );
      })}
    </StyledComponent>
  );
};

export default SelectInsuranceRenewalMonth;
