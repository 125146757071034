import * as React from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import queryString from "query-string";

import AppProvider, { useAppContext } from "core/context/appContext";
import { SESSION_KEY, setUserkey } from "core/functions/userkey";
import { isAuth } from "core/api/auth";
import { getRepresent } from "core/api/mycar";

import GlobalStyles from "styles/global";
import theme from "styles/theme";
import Routes from "core/routes";
import Layout from "components/layout";
import Loading from "elements/helper/loading";
import Message from "elements/helper/message";

import { RecoilRoot } from "recoil";
import ModalContainer from "components/Modals/ModalContainer";

import "core/appLink/initialize";

const { useState, useEffect } = React;

const App: React.FC = () => {
  const appCtx = useAppContext();
  const location = useLocation();

  const [state, setState] = useState<{
    userkey: string | null;
    pending: boolean;
  }>({
    userkey: null,
    pending: true,
  });

  const getAuthuserkey = async () => {
    try {
      const authRes = await isAuth();
      return authRes?.data?.result || null;
    } catch {
      return null;
    }
  };

  const getMycar = async (userkey: string) => {
    try {
      const mycarRes: any = await getRepresent(userkey);
      return mycarRes?.data?.result || null;
    } catch {
      throw new Error("Invalid userkey");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        /**
         * 유저키 계산 순서
         *
         * 1. query parameter
         * 2. authUserkey
         * 3. local storage
         */
        const { userkey: queryParamUserkey } = queryString.parse(
          window.location.search,
        );

        const authuser = await getAuthuserkey();

        const userkey =
          queryParamUserkey ||
          authuser?.authuserkey ||
          window.localStorage.getItem(SESSION_KEY);

        // 유저키가 없는 경우, 서비스 이용 불가
        if (!userkey) {
          throw new Error("Invalid userkey");
        }

        // 마이카 정보 불러오기
        const mycar = await getMycar(userkey);

        // 마이카 정보 context에 저장
        appCtx.setState({
          ...appCtx.state,
          authuser,
          mycar,
        });

        // state 저장
        setState({ ...state, userkey, pending: false });

        // 유저키를 session에 저장 해둠
        setUserkey(userkey);
      } catch (e) {
        setState({ ...state, pending: false });
      }
    })();
  }, []);

  // 마이카 정보 불러오는 중
  if (state.pending) {
    return <Loading isShow />;
  }

  // 유효하지 않은 유저키
  if (!state.userkey && location.pathname !== "/agreement") {
    return <Message>잘못된 유저키입니다.</Message>;
  }

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <ModalContainer />
        <GlobalStyles />
        <Layout>
          <Routes />
        </Layout>
      </ThemeProvider>
    </RecoilRoot>
  );
};

export default () => (
  <BrowserRouter>
    <AppProvider>
      <App />
    </AppProvider>
  </BrowserRouter>
);
