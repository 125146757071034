import * as React from "react";
import styled, { css } from "styles/styled";

import { IContProps } from "containers/qnaCont/interface";
import PageTemp from "components/template/pageTemp";
import FormItem from "components/form/formItem";
import Button from "elements/form/button";
import Icon from "elements/helper/icon";

import SelectInsuranceRenewalMonth from "components/insurance/insuranceHome/SelectInsuranceRenewalMonth";

import { useRecoilValue, useRecoilState } from "recoil";
import insuranceQnaStateAtom from "recoil/atoms/insuranceQnaStateAtom";
import calculateEstimationAtom from "recoil/atoms/calculateEstimationAtom";
import { useAppContext } from "core/context/appContext";

import farawayDate from "./images/group-6.svg";
import errorImage from "./images/group-12.svg";
import { openWebview } from "core/appLink/deepLink";

import DBImage from "./images/insurance/DB손해보험.png";
import AXAImage from "./images/insurance/AXA손해보험.png";
import KBImage from "./images/insurance/KB손해보험.png";
import MGImage from "./images/insurance/MG손해보험.png";
import CarrotImage from "./images/insurance/당근손해보험.png";
import LotteImage from "./images/insurance/롯데손해보험.png";
import MeritzImage from "./images/insurance/메리츠손해보험.png";
import SamsungImage from "./images/insurance/삼성화재.png";
import HanaImage from "./images/insurance/하나손해보험.png";
import HanhwaImage from "./images/insurance/한화손해보험.png";
import HyundaiImage from "./images/insurance/현대해상.png";
import HGImage from "./images/insurance/흥국손해보험.png";

import { closeWebview } from "core/appLink/global";
import { getUserkey } from "core/functions/userkey";

import {
  clickShowEstimateNow,
  clickedEngineOilCrossSellingCta,
  clickedRedirectToHomeFromErrorPage,
  clickedOnChatFromErrorPage,
} from "core/eventMatrics/action";

const { useMemo, useState, useEffect } = React;
const Component = styled.div<{
  isError: boolean;
  disableButton: boolean;
  showCalculatedResultPage: boolean;
  calculableDateAfterUserMonthClick: boolean;
}>`
  ${({
    isError,
    disableButton,
    calculableDateAfterUserMonthClick,
    theme: { colors },
  }) => css`
    .strong--blue {
      color: ${colors.dodgerBlue};
    }
    .helpful-clickable-description {
      font-size: 16px;
      font-weight: 600;
    }
    .qna-page {
      &__add-description {
        padding-bottom: 22px;
        ${calculableDateAfterUserMonthClick && "padding: 12px 20px"};
        ${calculableDateAfterUserMonthClick && "margin-bottom: 24px"};
        border-radius: ${calculableDateAfterUserMonthClick ? "16px" : "none"};
        background-color: ${
          calculableDateAfterUserMonthClick ? "#f4f4f4" : "none"
        };
        font-size: 12px;
        color: ${colors.gray800};
        text-align: center;
        .bold {
          color: #000000;
          font-weight: bold;
        }
        .bold-description {
          color: #0068ff;
          text-decoration: underline;
        }
      }

      &__agreement {
        margin-bottom: 20px;

        &__description {
          margin-bottom: 16px;
          opacity: 0;
          transition: 300ms opacity ease;

          &.active {
            opacity: 1;
          }
        }

        &__wrap {
          display: flex;
          align-items: center;
        }

        &__label {
          padding: 10px 0;
        }

        &__view {
          margin-left: auto;
          position: relative;
          top: -2px;
          text-decoration: underline;
          font-size: 14px;
          color: ${colors.gray600};
        }
      }
      &__image {
        padding-top: 194px;
        margin: 0 auto;
        display: block;
        @media only screen and (max-width: 480) {
          padding-top: 97px;
        }
      }
      /* qna-page__main-text */

      &__main-text {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #222222;
      }
      &__sub-text {
        text-align: center;
        padding-top: 8px;
        font-size: 16px;
        font-weight: normal;
        color: #9e9e9e;
        .bold {
          font-weight: bold;
          color: #5c5c5c;
        }
      }
    }
    .select-renewal-month-description {
      height: 48px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: ${colors.nero};
    }
    #cta-button {
      border-radius: 8px;
      height: 56px;
      font-weight: 600;
      background-color: ${colors.black};
      color: ${
        isError || disableButton ? `${colors.silver}` : `${colors.white}`
      };
    }
    .flex-insurance-image {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 194px;
      @media only screen and (max-width: 480) {
        padding-top: 97px;
      }
      .insurance-image {
        width: 44px;
        height: 44px;
        &__main {
          width: 68px;
          height: 68px;
          border: 2px solid#9e9e9e;
          border-radius: 50%;
        }
      }
    }
  `}
}
`;

const QnaPage: React.FC<IContProps> = ({
  showErrorPage,
  showEstimationPossibleDatePage,
  showCalculatedResultPage,
  pending,
  mycar,
  step,
  lastStep,
  answer,
  answers,
  agreement,
  agreementMessage,
  isError,
  integratedUIError,
  isVerifyingSms,
  toggleIsVerifyingSms,
  hasRecoverableSMSVerificationError,
  isProcessingVerification,
  SMSVerificationObject,
  setSMSInput,
  SMSInput,
  userTelecom,
  setUserTelecom,
  userSelectedMonth,
  setUserSelectedMonth,
  requestSMSVerificationNumber,
  processRequestSMSVerificationNumberSuccess,
  carInsuranceRegistrationMeta,
  questionItem,
  integratedQuestionItems,
  onModal,
  onInput,
  onSubmit,
  onToggleAgreement,
  onLinkToAgreement,
  toggleWaitingForResponseType,
}) => {
  const [imageToShow, setImageToShow] = useState(farawayDate);
  const [calculateEstimation, setCalculateEstimation] = useRecoilState(
    calculateEstimationAtom,
  );
  useEffect(() => {
    if (showErrorPage) {
      setImageToShow(errorImage);
    } else {
      setImageToShow(farawayDate);
    }
  }, [showErrorPage, showEstimationPossibleDatePage, calculateEstimation]);

  const [textToShow, setTextToShow] = useState("");
  const [subtextToShow, setSubtextToShow] = useState("");

  const insuranceQnaState = useRecoilValue(insuranceQnaStateAtom);

  const appCtx = useAppContext();
  const [nameToDisplay, setNameToDisplay] = useState(
    appCtx.state?.mycar?.ownerName,
  );

  const calculableDate = calculateEstimation.calculableDate || "";
  const {
    errorMessageText,
    calculableDateAfterUserMonthClick,
    ceInsuranceDetails,
    ceRequestId,
  } = calculateEstimation;

  const calculableDateNumbers = calculableDate.split("-");
  const calculableDateMonth = calculableDateNumbers[1];
  const calculableDateDay = calculableDateNumbers[2];

  const [
    carInsuranceRegistrationQuestion,
    setCarInsuranceRegistrationQuestion,
  ] = useState(carInsuranceRegistrationMeta?.question);
  const [
    carInsuranceRegistrationDescription,
    setcarInsuranceRegistrationDescription,
  ] = useState(carInsuranceRegistrationMeta?.description);

  useEffect(() => {
    setCarInsuranceRegistrationQuestion(carInsuranceRegistrationMeta?.question);
    setcarInsuranceRegistrationDescription(
      carInsuranceRegistrationMeta?.description,
    );
  }, [carInsuranceRegistrationMeta]);

  const integratedFormItems = useMemo(
    () =>
      integratedQuestionItems?.map((question, index) => {
        const currentErrorMessage = question.errorMessage;
        const currentQuestion = question.items[0];
        const { type, label, placeholder } = currentQuestion;
        const props = { label, placeholder };
        const currentAnswer = answers[step + index - 1];
        let currentValid = true;
        if (index === 1) {
          currentValid =
            integratedUIError?.isValidSocialRegistrationNumber ?? false;
        } else if (index === 2) {
          currentValid = integratedUIError?.isValidMobileNumber ?? true;
        }
        return (
          <React.Fragment key={step + index}>
            <FormItem
              type={type}
              dataSource={{
                ...props,
                isError,
                isIntegratedError: !currentValid,
                mycar,
                errorMessage: currentErrorMessage,
                defaultValue: currentAnswer,
                userTelecom,
                setUserTelecom,
              }}
              callback={(answer: any, type: string) => onInput(answer, type)}
            />
          </React.Fragment>
        );
      }, []),
    [
      mycar,
      step,
      isError,
      onInput,
      integratedUIError,
      answers,
      integratedQuestionItems,
      userTelecom,
      setUserTelecom,
      calculateEstimation,
    ],
  );

  const formItems = useMemo(
    () =>
      questionItem.items?.map((question, index) => {
        const { type, ...props } = question;
        return (
          <React.Fragment key={step + index}>
            <FormItem
              type={type}
              dataSource={{
                ...props,
                isError,
                mycar,
                errorMessage: questionItem.errorMessage,
                defaultValue: answer,
              }}
              callback={(answer: any) => onInput(answer)}
            />
          </React.Fragment>
        );
      }, []),
    [mycar, step, answer, isError, questionItem, onInput],
  );

  const { question, description, addDescription, buttonText } = questionItem;
  const [descriptionText, setDescriptionText] = useState("");
  useEffect(() => {
    if (calculableDateAfterUserMonthClick) {
      if (calculableDateAfterUserMonthClick) {
        setDescriptionText(
          '*최저가 보험 찾기를 놓치지 않으시려면<br/><span class="bold">홈>차량이미지>보험</span>에서 정확한 보험 갱신일을 입력해 주세요',
        );
      }
    } else {
      setDescriptionText(addDescription || "");
    }
  }, [addDescription, calculableDateAfterUserMonthClick]);

  const [disableButton, toggleDisableButton] = useState(true);

  useEffect(() => {
    if (integratedQuestionItems) {
      const { 3: identifier } = answers;
      const { userTelecom: userTelecomState } = insuranceQnaState;
      // console.log('useEffect identifier: ', identifier);
      // console.log(
      //   'integratedQuestionItems integratedUIError: ',
      //   integratedUIError,
      // );
      if (carInsuranceRegistrationMeta?.question) {
        if (userSelectedMonth) {
          toggleDisableButton(false);
        } else {
          toggleDisableButton(true);
        }
      } else if (
        (integratedUIError?.isValidMobileNumber && identifier.length === 10) ||
        // (integratedUIError?.isValidMobileNumber && identifier.length === 10) ||
        // (integratedUIError?.isValidMobileNumber &&
        //   integratedUIError?.isValidSocialRegistrationNumber &&
        //   userTelecom)
        (integratedUIError?.isValidMobileNumber &&
          integratedUIError?.isValidSocialRegistrationNumber &&
          userTelecomState)
      ) {
        toggleDisableButton(false);
      } else {
        // console.log('userTelecom: ', userTelecom);
        // console.log('insuranceQnaState: ', insuranceQnaState);

        toggleDisableButton(true);
      }
    } else {
      // Disabling 'no-lonely-if' enables more flexible (conditions might change later) and
      // clearer conditional statements and block.
      /* eslint-disable-next-line no-lonely-if */
      if (step === 1) {
        if (integratedUIError?.isValidInsuranceType) {
          toggleDisableButton(false);
        } else {
          toggleDisableButton(true);
        }
      } else if (step === 2) {
        if (integratedUIError?.isValidLiabilityType) {
          toggleDisableButton(false);
        } else {
          toggleDisableButton(true);
        }
      }
    }
  }, [
    integratedUIError,
    integratedQuestionItems,
    step,
    userTelecom,
    insuranceQnaState,
    userSelectedMonth,
    carInsuranceRegistrationMeta,
  ]);

  useEffect(() => {
    if (isVerifyingSms) {
      if (SMSInput?.length !== 6) {
        toggleDisableButton(true);
      } else {
        toggleDisableButton(false);
        document.getElementById("smsVerification")?.blur();
        setTimeout(() => {
          document.getElementById("cta-button")?.click();
        }, 10);
      }
    }
  }, [isVerifyingSms, SMSInput]);

  const [ctaButtonText, setCtaButtonText] = useState("");

  const [imageDivs, setImageDivs] = useState(<></>);

  const [redirectInSecs, setRedirectInSecs] = useState(3000);
  const [alreadyRedirected, toggleAlreadyRedirected] = useState(false);
  useEffect(() => {
    if (showCalculatedResultPage && !alreadyRedirected) {
      if (redirectInSecs) {
        let interval = setInterval(() => {
          setRedirectInSecs(redirectInSecs - 1000);
          clearInterval(interval);
          if (redirectInSecs === 1000) {
            setRedirectInSecs(0);

            const url = `/estimation/list?requestId=${calculateEstimation.ceRequestId}`;
            openWebview(`${window.location.origin}${url}`, {
              titlebaryn: "N",
              singletopyn: "Y",
            });
          }
        }, 1000);
      }
    }
  }, [showCalculatedResultPage, redirectInSecs, calculateEstimation]);

  useEffect(() => {
    if (carInsuranceRegistrationMeta?.question) {
      setCtaButtonText("가입 월 제출하기");
    } else if (isVerifyingSms || hasRecoverableSMSVerificationError) {
      setCtaButtonText("인증하기");
    } else if (isProcessingVerification) {
      setCtaButtonText("인증 중...");
    } else if (showEstimationPossibleDatePage) {
      setImageDivs(
        <img
          src={imageToShow}
          alt="estimation possible date car"
          className="qna-page__image"
        />,
      );
      setTextToShow(
        `${nameToDisplay}님! ${calculableDateMonth}월 ${calculableDateDay}일부터<br />최저가 보험을 찾을 수 있어요`,
      );
      setSubtextToShow(
        "갱신일 D-29부터 보험 찾기가 가능해요<br />손해없이 보험에 가입하도록 알려드릴게요",
      );
      setCtaButtonText("엔진오일도? ㅋㄷㅋㄷ!");
      toggleDisableButton(false);
    } else if (showErrorPage) {
      setDescriptionText(
        '*이 페이지가 또 보이시면 <span class="bold-description">고객센터</span>로 알려주세요.',
      );
      setImageDivs(
        <img
          src={imageToShow}
          alt="estimation possible date car"
          className="qna-page__image"
        />,
      );
      setTextToShow("이용에 불편을 드려 죄송해요");
      setSubtextToShow(errorMessageText);

      setCtaButtonText("홈으로 이동");
      toggleDisableButton(false);
    } else if (showCalculatedResultPage) {
      setTextToShow(`${nameToDisplay}님!`);
      let indexToOrder = 0;
      const lowestInsuName =
        ceInsuranceDetails && ceInsuranceDetails[0].cdCarInsuranceCorp.insuName;
      let insuName = "";
      const mapOfImages = ceInsuranceDetails
        ?.slice(0, 5)
        .map((detail: any, index: number) => {
          insuName = detail.cdCarInsuranceCorp.insuName;
          let insuImage = DBImage;
          let classNameString = "insurance-image";
          switch (insuName) {
            case "DB손해보험":
              insuImage = DBImage;
              break;
            case "AXA손해보험":
              insuImage = AXAImage;
              break;
            case "KB손해보험":
              insuImage = KBImage;
              break;
            case "MG손해보험":
              insuImage = MGImage;
              break;
            case "캐롯손해보험":
              insuImage = CarrotImage;
              break;
            case "롯데손해보험":
              insuImage = LotteImage;
              break;
            case "메리츠화재":
              insuImage = MeritzImage;
              break;
            case "삼성화재":
              insuImage = SamsungImage;
              break;
            case "하나손해보험":
              insuImage = HanaImage;
              break;
            case "한화손해보험":
              insuImage = HanhwaImage;
              break;
            case "현대해상":
              insuImage = HyundaiImage;
              break;
            case "흥국화재":
              insuImage = HGImage;
              break;
            default:
              break;
          }
          switch (index) {
            case 0:
              classNameString += "__main";
              indexToOrder = 3;
              break;
            case 1:
              indexToOrder = 4;
              break;
            case 2:
              indexToOrder = 2;
              break;
            case 3:
              indexToOrder = 5;
              break;
            case 4:
              indexToOrder = 1;
              break;
            default:
              break;
          }
          return (
            <img
              src={insuImage}
              style={{ order: indexToOrder }}
              alt="insurance company"
              className={classNameString}
            />
          );
        });
      setImageDivs(<div className="flex-insurance-image">{mapOfImages}</div>);
      setCtaButtonText(`보험료 확인까지 ${redirectInSecs / 1000}초`);
      setSubtextToShow(
        `올해는 <span class='bold'>94%의 확률</span>로<br /><span class='bold'>${lowestInsuName} 다이렉트</span>에<br />가입하는 것이 가장 저렴해요`,
      );
      toggleDisableButton(false);
    } else {
      pending
        ? setCtaButtonText("견적 요청중...")
        : setCtaButtonText(`${buttonText || "다음"}`);
    }
  }, [
    isVerifyingSms,
    pending,
    step,
    lastStep,
    carInsuranceRegistrationMeta,
    isProcessingVerification,
    showEstimationPossibleDatePage,
    showCalculatedResultPage,
    redirectInSecs,
    showErrorPage,
    calculateEstimation,
  ]);

  const onRedirect = () => {
    toggleAlreadyRedirected(true);
    if (showErrorPage) {
      clickedRedirectToHomeFromErrorPage();
      closeWebview(true);
    } else if (showEstimationPossibleDatePage) {
      const userkey = getUserkey();
      clickedEngineOilCrossSellingCta(
        calculableDateAfterUserMonthClick && "after month click",
      );
      openWebview(
        `${process.env.REACT_APP_TECHSHOP_LINK}/engine-oil-detail?modal=true&userkey=${userkey}`,
        {
          titlebaryn: "N",
          singletopyn: "Y",
        },
      );
    } else if (showCalculatedResultPage) {
      clickShowEstimateNow();
      const url = `/estimation/list?requestId=${calculateEstimation.ceRequestId}`;
      openWebview(`${window.location.origin}${url}`, {
        titlebaryn: "N",
        singletopyn: "Y",
      });
    }
  };

  const openCardocChat = () => {
    clickedOnChatFromErrorPage();
    window.location.href = "cardoc://chatwithcardoc";
  };

  return (
    <Component
      className="qna-page-component"
      isError={isError}
      disableButton={disableButton}
      showCalculatedResultPage={showCalculatedResultPage}
      calculableDateAfterUserMonthClick={calculableDateAfterUserMonthClick}
    >
      <PageTemp>
        <PageTemp.CtaFlexBox>
          <PageTemp.PageHeader>
            {showEstimationPossibleDatePage ||
            showCalculatedResultPage ||
            showErrorPage ? (
              imageDivs
            ) : (
              <>
                {carInsuranceRegistrationQuestion || question}
                {carInsuranceRegistrationDescription ? (
                  <p>
                    <small
                      className="select-renewal-month-description"
                      dangerouslySetInnerHTML={{
                        __html: carInsuranceRegistrationDescription,
                      }}
                    />
                  </p>
                ) : (
                  description && (
                    <p
                      className="helpful-clickable-description"
                      onClick={() => onModal(description.action)}
                    >
                      <Icon size="18px" icon="caoution-gray" />
                      <small
                        dangerouslySetInnerHTML={{ __html: description.text }}
                      />
                    </p>
                  )
                )}
              </>
            )}
          </PageTemp.PageHeader>
          {carInsuranceRegistrationQuestion ? (
            <SelectInsuranceRenewalMonth
              userSelectedMonth={userSelectedMonth}
              setUserSelectedMonth={setUserSelectedMonth}
            />
          ) : (
            <>
              <>
                {showEstimationPossibleDatePage ||
                showCalculatedResultPage ||
                showErrorPage ? (
                  <>
                    <div
                      className="qna-page__main-text"
                      dangerouslySetInnerHTML={{ __html: textToShow }}
                    />
                    <div
                      className="qna-page__sub-text"
                      dangerouslySetInnerHTML={{
                        __html: subtextToShow,
                      }}
                    />
                  </>
                ) : (
                  <>
                    {formItems}
                    {integratedFormItems}
                    {(isVerifyingSms || hasRecoverableSMSVerificationError) && (
                      <FormItem
                        type="smsVerification"
                        step={step}
                        dataSource={{
                          label: "인증번호",
                          placeholder: "6자리 입력",
                          isError,
                          mycar,
                          errorMessage: questionItem.errorMessage,
                          defaultValue: SMSInput,
                          setSMSInput,
                          requestSMSVerificationNumber,
                          processRequestSMSVerificationNumberSuccess,
                          toggleWaitingForResponseType,
                        }}
                        callback={(answer: any) => onInput(answer)}
                      />
                    )}
                  </>
                )}
              </>
            </>
          )}
          <PageTemp.CtaButton>
            {descriptionText && (
              <div
                onClick={showErrorPage ? openCardocChat : undefined}
                className="qna-page__add-description"
                dangerouslySetInnerHTML={{
                  __html: descriptionText,
                }}
              />
            )}
            <Button
              id="cta-button"
              color={isError || disableButton ? "gray" : "dark"}
              disabled={pending || isError || disableButton}
              onClick={
                showEstimationPossibleDatePage ||
                showErrorPage ||
                showCalculatedResultPage
                  ? onRedirect
                  : onSubmit
              }
            >
              {ctaButtonText}
            </Button>
          </PageTemp.CtaButton>
        </PageTemp.CtaFlexBox>
      </PageTemp>
    </Component>
  );
};

export default QnaPage;
