import * as React from "react";
import { FormType } from "core/interface/qnaInterface";
import RadioTypeForm from "components/form/radioTypeForm";
import InputTypeForm from "components/form/inputTypeForm";

interface IProps {
  type: FormType;
  dataSource: any;
  callback: Function;
  step?: number;
}

const FormItem: React.FC<IProps> = ({ type, dataSource, step, callback }) => {
  switch (type) {
    case "string":
    case "identifier":
      return (
        <InputTypeForm
          dataSource={{
            ...dataSource,
            type,
          }}
          callback={(nextState, type) => callback(nextState, type)}
        />
      );
    case "telNumber":
      return (
        <>
          <InputTypeForm
            dataSource={{
              ...dataSource,
              type: "userTelecom",
            }}
            callback={(nextState, type) => callback(nextState, type)}
          />
          <InputTypeForm
            dataSource={{
              ...dataSource,
              type,
            }}
            callback={(nextState, type) => callback(nextState, type)}
          />
        </>
      );
    case "smsVerification":
      return (
        <InputTypeForm
          step={step}
          dataSource={{
            ...dataSource,
            type,
          }}
          callback={(nextState, type) => callback(nextState, type)}
        />
      );

    case "radio":
      return (
        <RadioTypeForm
          dataSource={dataSource}
          callback={nextState => callback(nextState)}
        />
      );

    default:
      return null;
  }
};

export default FormItem;
