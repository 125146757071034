import * as React from "react";
import styled, { css } from "styles/styled";

type ColorType = "primary" | "dark" | "border" | "gray";

type ButtonSizeType = "small";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  size?: ButtonSizeType;
  color?: ColorType;
  disabled?: boolean;
}

const StyledComponent = styled.button<{
  size?: ButtonSizeType;
  color?: ColorType;
}>`
  ${({ size, color, theme: { sizes, colors } }) => css`
    width: 100%;
    height: 50px;
    border: none;
    border-radius: ${sizes.borderRadius};
    transition: opacity 250ms, background-color 500ms ease;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: inherit;
      text-decoration:none;
    }

    ${
      size === "small" &&
      css`
        height: 42px;
      `
    }

    ${
      color === "primary" &&
      css`
        background-color: ${colors.primary};
        color: white;

        &[disabled] {
          opacity: 0.5;
        }
      `
    }  

    ${
      color === "dark" &&
      css`
        background-color: ${colors.dark};
        color: white;
      `
    }

    ${
      color === "gray" &&
      css`
        background-color: ${colors.gray300};
        color: ${colors.gray700};

        &[disabled] {
          opacity: 0.65;
        }
      `
    }
      
    ${
      color === "border" &&
      css`
        border: 1px solid ${colors.primary};
        background-color: white;
        color: ${colors.primary};
      `
    }
  `}
`;

const Button: React.FC<IProps> = ({
  className,
  size,
  color = "primary",
  disabled = false,
  children,
  ...attrs
}) => (
  <StyledComponent
    className={className}
    {...attrs}
    size={size}
    color={color}
    disabled={disabled}
  >
    {children}
  </StyledComponent>
);

export default Button;
