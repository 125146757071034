import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

declare global {
  interface Window {
    device: "ios" | "android" | "unknown";
  }
}

(() => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  const ios = /iphone|ipod|ipad/.test(userAgent);
  const android = /(android)/i.test(navigator.userAgent);

  if (ios && !safari) {
    window.device = "ios";
  } else if (android) {
    window.device = "android";
  } else {
    window.device = "unknown";
  }

  if (process.env.NODE_ENV === "production") {
    window.console.log = () => {};
  }
})();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
