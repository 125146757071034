import * as PATH from "core/consts/path";

/**
 * 앱바 타이틀
 */
export const appbarTitle = {
  [PATH.ESTIMATION_LIST]: "받은 견적함",
  [PATH.ESTIMATION_DETAIL]: "나의 요청내역",
};

/**
 * 페이지 별 뒤로가기 버튼, 닫기 버튼, 타이틀을 노출 할지 여부
 */
export default (
  path: string,
): null | {
  hideLeftButton?: boolean;
  hideRightButton?: boolean;
} => {
  if (path === PATH.ESTIMATION_REQ_COMPLETE) {
    // 견적 요청 완료 - 앱바 버튼 노출 x
    return {
      hideLeftButton: true,
      hideRightButton: true,
    };
  }

  return null;
};
