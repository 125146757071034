import axios from "axios";

const userkey = window.localStorage.getItem("userkey") || "";

export const cardocApiCreator = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 120000,
  withCredentials: true,
});

export const connectApiCreator = axios.create({
  baseURL: process.env.REACT_APP_CONNECT_HOST,
  timeout: 120000,
  withCredentials: true,
});

export const connectApiCreatorWithUserkey = axios.create({
  baseURL: process.env.REACT_APP_CONNECT_HOST,
  timeout: 120000,
  withCredentials: true,
  headers: {
    ...(userkey && {
      "X-Cardoc-Userkey": userkey,
    }),
  },
});
