import * as React from "react";
import styled from "styles/styled";
import Modal from "elements/helper/modal";
import Button from "elements/form/button";

interface IProps {
  isShow: boolean;
  onLinkToLogin: () => void;
  onClose: () => void;
}

const StyledComponent = styled.div`
  p {
    padding-bottom: 25px;
    font-size: 19px;
    font-weight: 500;
  }
`;

const LoginModal: React.FC<IProps> = ({ onLinkToLogin, ...props }) => (
  <Modal {...props}>
    <StyledComponent>
      <p>
        3초만에 로그인 하고
        <br />
        다양한 카닥의 서비스와 혜택을
        <br />
        경험해보세요
      </p>
      <Button onClick={onLinkToLogin}>확인</Button>
    </StyledComponent>
  </Modal>
);

export default LoginModal;
