import * as React from "react";

import Section from "elements/ui/section";
import CheckItem from "elements/ui/checkItem";
import { toast } from "core/appLink/global";

interface IProps {
  dataSource: {
    mycar: any;
    name: string;
    radioItems: {
      value: string;
      descriptions: string[];
      validateMycar: {
        columms: string[];
        message: { [key: string]: string };
      };
    }[];
    defaultValue: string;
  };
  callback: (nextState: any) => void;
}

const { useCallback } = React;

const RadioTypeForm: React.FC<IProps> = ({ dataSource, callback }) => {
  const handleChange = useCallback(
    e => {
      callback(e.target.value);
    },
    [callback],
  );

  const { mycar, name, radioItems, defaultValue } = dataSource;

  return (
    <>
      {radioItems.map((item, index) => (
        <Section key={item.value} t={8} b={8}>
          <CheckItem
            id={`${name}-${item.value}`}
            name={name}
            label={item.value}
            value={item.value}
            checked={defaultValue === item.value}
            onChange={e => {
              if (!!item.validateMycar) {
                let inValidmycarColumn;
                // 마이카 정보가 필요한 필드
                item.validateMycar.columms.some(column => {
                  if (!mycar[column]) {
                    inValidmycarColumn = column;
                    return true;
                  }
                  return false;
                });

                if (inValidmycarColumn) {
                  toast(item.validateMycar.message?.[inValidmycarColumn] || "");
                  return;
                }
              }

              handleChange(e);
            }}
          >
            {item.descriptions &&
              item.descriptions.map(description => (
                <div
                  key={description}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ))}
          </CheckItem>
        </Section>
      ))}
    </>
  );
};

export default RadioTypeForm;
