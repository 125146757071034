import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";

import styled, { css } from "styles/styled";
import { QNA as QNA_PATH } from "core/consts/path";
import { goBack, closeWebview } from "core/appLink/global";
import { textOverflow } from "styles/mixin";
import Icon from "elements/helper/icon";
import appbarContext, { appbarTitle } from "./appbarContext";

import { useRecoilState } from "recoil";
import topNavbarAtom from "recoil/atoms/topNavbarAtom";
import calculateEstimationAtom from "recoil/atoms/calculateEstimationAtom";

import { clickedCloseAndReturnToHome } from "core/eventMatrics/action";

const { useRef, useMemo } = React;

const getServiceAndStep = (): [string, number] | null => {
  const location = useLocation();
  if (!location.pathname.startsWith(QNA_PATH)) {
    return null;
  }

  const subPath = location.pathname.split(`${QNA_PATH}/`)?.[1] || "";
  const [service, answers] = subPath.split("/");

  try {
    return [service, JSON.parse(answers || "[]").length + 1];
  } catch {
    return null;
  }
};

const PrevButton: React.FC = () => {
  const history = useHistory();
  const serviceAndStep = getServiceAndStep();
  const location = useLocation();

  return (
    <span
      onClick={() => {
        if (location.pathname === "/estimation/list") {
          closeWebview(true);
        } else if ((window as any).app) {
          goBack();
        } else {
          history.goBack();
        }
      }}
    >
      <Icon type="png" size="36px" icon="ico-gnb-prev-36-black" />
    </span>
  );
};

const CloseButton: React.FC = () => {
  const serviceAndStep = getServiceAndStep();

  const [calculateEstimation, setCalculateEstimation] = useRecoilState(
    calculateEstimationAtom,
  );
  const { possibleEstimationDatePage, calculableDateAfterUserMonthClick } =
    calculateEstimation;

  return (
    <span
      onClick={() => {
        if (possibleEstimationDatePage) {
          clickedCloseAndReturnToHome(
            calculableDateAfterUserMonthClick && "from select calendar page",
          );
        }
        closeWebview(true);
      }}
    >
      <Icon type="png" size="36px" icon="ico-gnb-close-36-black" />
    </span>
  );
};

const StyledComponent = styled.div`
  ${({ theme: { sizes } }) => css`
    .layout {
      &__header {
        width: 100%;
        height: ${sizes.headerHeight};
        height: calc(${sizes.headerHeight} + constant(safe-area-inset-top));
        height: calc(${sizes.headerHeight} + env(safe-area-inset-top));
        padding: 0 8px 8px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 99;
        background-color: white;

        &__title {
          position: fixed;
          top: 24px;
          top: calc(24px + constant(safe-area-inset-top));
          top: calc(24px + env(safe-area-inset-top));
          left: 50%;
          transform: translateX(-50%);
          font-size: 17px;
          font-weight: 500;
          ${textOverflow}
        }

        &__left-icon {
          position: fixed;
          top: 18px;
          top: calc(18px + constant(safe-area-inset-top));
          top: calc(18px + env(safe-area-inset-top));
          left: 10px:
          width: 36px;
          height: 43px;
        }
        &__right-icon {
          position: fixed;
          top: 18px;
          top: calc(18px + constant(safe-area-inset-top));
          top: calc(18px + env(safe-area-inset-top));
          right 10px;
          width: 36px;
          height: 43px;
        }
      }

      &__header_gray {
        width: 100%;
        height: ${sizes.headerHeight};
        height: calc(${sizes.headerHeight} + constant(safe-area-inset-top));
        height: calc(${sizes.headerHeight} + env(safe-area-inset-top));
        padding: 0 8px 8px;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 99;
        background-color: #f7f7f8;

        &__title {
          position: fixed;
          top: 24px;
          top: calc(24px + constant(safe-area-inset-top));
          top: calc(24px + env(safe-area-inset-top));
          left: 50%;
          transform: translateX(-50%);
          font-size: 17px;
          font-weight: 500;
          ${textOverflow}
        }

        &__left-icon {
          position: fixed;
          top: 18px;
          top: calc(18px + constant(safe-area-inset-top));
          top: calc(18px + env(safe-area-inset-top));
          left: 10px:
          width: 36px;
          height: 43px;
        }
        &__right-icon {
          position: fixed;
          top: 18px;
          top: calc(18px + constant(safe-area-inset-top));
          top: calc(18px + env(safe-area-inset-top));
          right 10px;
          width: 36px;
          height: 43px;
        }
      }

      &__main {
        min-height: 100vh;
        padding-top: ${sizes.headerHeight};
        padding-top: calc(
          ${sizes.headerHeight} + constant(safe-area-inset-top)
        );
        padding-top: calc(${sizes.headerHeight} + env(safe-area-inset-top));
      }

      &__main_gray {
        min-height: 100vh;
        padding-top: ${sizes.headerHeight};
        padding-top: calc(
          ${sizes.headerHeight} + constant(safe-area-inset-top)
        );
        padding-top: calc(${sizes.headerHeight} + env(safe-area-inset-top));
        background-color: #f7f7f8;
      }
    }
  `}
`;

const Layout: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const firstPagePath = useRef(location.pathname);

  const doNotShowRoute = ["/estimation/complete"];
  const [topNavbar, setTopNavbar] = useRecoilState(topNavbarAtom);

  const doNotShow =
    doNotShowRoute.indexOf(location.pathname) > -1 || topNavbar.hideTopNavbar;
  // 앱바 구성 요소
  const appbar = useMemo(
    () => appbarContext(location.pathname),
    [location.pathname],
  );

  // 첫번째 웹뷰 페이지에서는 좌측에 닫기 버튼을 노출
  const isFirstPage = useMemo(() => {
    return firstPagePath.current === location.pathname;
  }, [location.pathname]);

  const showPrevButton = useMemo(() => {
    const noShowPaths = ["/insurance-companies-list"];
    const showIndex = noShowPaths.indexOf(location.pathname);
    return showIndex > -1;
  }, [location.pathname]);

  //insurance-companies-list 에서는 별도의 style을 적용.
  const isInsuranceCompanies = useMemo(() => {
    return "/insurance-companies-list" == location.pathname;
  }, [location.pathname]);

  return (
    <StyledComponent>
      <header
        className={
          isInsuranceCompanies ? "layout__header_gray" : "layout__header"
        }
      >
        {!doNotShow && (
          <span className="layout__header__left-icon">
            {/* 첫번째 웹뷰 페이지에서는 닫기 버튼을, 그 외에는 뒤로가기 버튼을 노출 */}

            {!showPrevButton &&
            (topNavbar.showCloseOnlyOnLeft ||
              (!appbar?.hideLeftButton && isFirstPage)) ? (
              <CloseButton />
            ) : (
              <PrevButton />
            )}
          </span>
        )}
        {!!(appbarTitle as Record<string, string>)?.[location.pathname] && (
          <h1 className="layout__header__title">
            {appbarTitle ?? appbarTitle[location.pathname]}
          </h1>
        )}
        {/* 첫번째 웹뷰 페이지가 아닌 경우 우측에 닫기 버튼을 노출 */}
        {!doNotShow &&
          !topNavbar.showCloseOnlyOnLeft &&
          location.pathname !== "/estimation/list" &&
          !appbar?.hideRightButton &&
          !isFirstPage && (
            <span className="layout__header__right-icon">
              <CloseButton />
            </span>
          )}
      </header>
      <main
        className={isInsuranceCompanies ? "layout__main_gray" : "layout__main"}
      >
        {children}
      </main>
    </StyledComponent>
  );
};

export default Layout;
