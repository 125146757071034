import * as React from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styles/styled";
import theme from "styles/theme";

const $toastRoot = document.getElementById("toast-root") as HTMLDivElement;
const DURATION = 3000;
let timer: ReturnType<typeof setTimeout> | null = null;

const { useState, useEffect, useCallback } = React;
const StyledComponent = styled.div<{ isMounted: boolean }>`
  width: calc(100vw - ${theme.sizes.baseMargin} * 2);
  max-width: 600px;
  padding: 12px 24px;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 15vh;
  border-radius: 16px;
  background: ${theme.colors.dodgerBlue};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: white;
  word-break: keep-all;
  opacity: 0;
  transition: opacity 250ms ease;

  ${({ isMounted }) =>
    isMounted &&
    css`
      opacity: 1;
    `};
`;

const Toast: React.FC = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);

  const handleClose = useCallback(() => {
    setIsMounted(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 100);
  }, []);

  return (
    <StyledComponent isMounted={isMounted} onClick={handleClose}>
      {children}
    </StyledComponent>
  );
};

const hide = () => {
  ReactDOM.unmountComponentAtNode($toastRoot);
};

const show = (message: string) => {
  if (timer) {
    clearTimeout(timer);
  }

  hide();
  ReactDOM.render(<Toast>{message}</Toast>, $toastRoot);
  timer = setTimeout(() => {
    hide();
  }, DURATION);
};

export default { show };
