import React from "react";
import { FormType } from "core/interface/qnaInterface";
import { telNumberFormat, indentifierFormat } from "core/functions/formatter";
import RowForm from "elements/form/rowForm";
import Input from "elements/form/input";

import Select from "elements/form/select";
import styled, { css } from "styled-components";

import { useRecoilValue, useRecoilState } from "recoil";

import modalMetaAtom from "recoil/atoms/modalMetaAtom";
import insuranceQnaStateAtom from "recoil/atoms/insuranceQnaStateAtom";

interface IProps {
  step?: number;
  dataSource: {
    type: FormType;
    label: string;
    value?: string;
    defaultValue?: string;
    isError: boolean;
    isIntegratedError?: boolean;
    errorMessage: string;
    setSMSInput: (value: string) => void;
    userTelecom?: number;
    setUserTelecom?: (telecom: number) => void;
  };
  callback: (nextState: string, type: string) => void;
}

const { useRef, useState, useCallback, useEffect } = React;

const getInputAttrs = (type: FormType) => {
  switch (type) {
    case "telNumber":
      return {
        // TODO: is returning type: 'tel' necessary or not (Not might be due to no longer used code.)
        // Confusing 🤷‍♂️  as there is 'tel' and 'telNumber', but leaving in as comment for now.
        // type: 'tel',
        maxLength: 13,
      };
    case "identifier":
      return {
        // TODO: is returning type: 'tel' necessary or not (Not might be due to no longer used code.)
        // Confusing 🤷‍♂️  as there is 'tel' and 'telNumber', but leaving in as comment for now.
        // type: 'tel',
        maxLength: 13,
      };
    default:
      return {};
  }
};

const StyledComponent = styled.div<{
  type: string;
  step: number | undefined;
}>`
  ${({ type, step }) => {
    if (type === "smsVerification" && step !== 3) {
      return css`
        visibility: hidden;
      `;
    }
  }}
`;

const InputTypeForm: React.FC<IProps> = ({ dataSource, step, callback }) => {
  const [insuranceQnaState, setInsuranceQnaState] = useRecoilState(
    insuranceQnaStateAtom,
  );

  const decodeValue = (type: FormType, value: string) => {
    const valueLength = value.length;
    // let unmaskedValue = value.slice(0, value.length);
    // console.log('unmaskedValue: ', unmaskedValue);
    let lastChar = value.charAt(valueLength - 1);
    console.log("lastChar: ", lastChar);
    let { unmaskedIdentifier: fullyUnmaskedIdentifier } = insuranceQnaState;
    const fullyUnmaskedIdentifierLength = fullyUnmaskedIdentifier.length;
    console.log("input value: ", value);
    console.log("input fullyUnmaskedIdentifier: ", fullyUnmaskedIdentifier);
    console.log("input valueLength: ", valueLength);
    console.log(
      "input fullyUnmaskedIdentifierLength: ",
      fullyUnmaskedIdentifierLength,
    );
    if (valueLength > fullyUnmaskedIdentifierLength) {
      fullyUnmaskedIdentifier += lastChar;
    } else {
      fullyUnmaskedIdentifier = fullyUnmaskedIdentifier.slice(0, valueLength);
      console.log(
        "input shorter fullyUnmaskedIdentifierLength: ",
        fullyUnmaskedIdentifierLength,
      );
    }

    switch (type) {
      case "telNumber":
        return value.replace(/[^0-9]/g, "");
      case "identifier":
        console.log(
          "input type form fullyUnmaskedIdentifier: ",
          fullyUnmaskedIdentifier,
        );
        setInsuranceQnaState({
          ...insuranceQnaState,
          unmaskedIdentifier: fullyUnmaskedIdentifier,
        });
        return value.replace(/[^0-9*]/g, "");
      default:
        return value;
    }
  };

  const encodeValue = (type: FormType, value: string) => {
    switch (type) {
      case "telNumber":
        return telNumberFormat(value);
      case "identifier":
        // console.log('identifier encodeValue: ', value);
        // return indentifierFormat(value);
        // return value;
        return value.replace(/(^[0-9]{6})([0-9*]{7})/, "$1-$2");
      default:
        return value;
    }
  };

  const prevScrollTop = useRef(0);

  const {
    type,
    label,
    isError,
    isIntegratedError,
    errorMessage,
    value,

    defaultValue,
    setSMSInput,
    userTelecom,
    setUserTelecom,
    ...attrs
  } = dataSource;

  // console.log('isIntegratedError: ', isIntegratedError);
  // console.log('errorMessage: ', errorMessage);

  const [state, setState] = useState({
    value: value || defaultValue || "",
    isShowError: false,
  });

  const handleSMSInput = (e: any) => {
    const { value } = e.target;
    const sixDigit = /^[0-9]{0,6}$/;
    if (value.match(sixDigit)) {
      setSMSInput(value);
    }
  };

  const handleChange = useCallback(
    e => {
      const nextValue = decodeValue(type, e.target.value);
      callback(nextValue, type);
      setState({
        value: nextValue,
        isShowError: true,
      });
    },
    [type, callback],
  );

  const restAttrs = {
    ...attrs,
    ...getInputAttrs(type),
  };

  const inputRef = useRef<any>(null);
  const clickBubble = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  return (
    <StyledComponent type={type} step={step} onClick={clickBubble}>
      <RowForm
        className="row-form"
        title={label}
        type={type}
        isIntegratedError={isIntegratedError}
        value={defaultValue}
        userTelecom={userTelecom}
      >
        <Input
          ref={inputRef}
          id={type}
          type={type}
          step={step}
          label={label}
          {...restAttrs}
          errorMessage={
            isIntegratedError || (state.isShowError && isError)
              ? errorMessage
              : ""
          }
          value={encodeValue(type, defaultValue || "")}
          onChange={type === "smsVerification" ? handleSMSInput : handleChange}
          onBlur={() => {
            window.scrollTo(0, prevScrollTop.current);
          }}
          userTelecom={userTelecom}
          setUserTelecom={setUserTelecom}
        />
      </RowForm>
    </StyledComponent>
  );
};

export default InputTypeForm;
