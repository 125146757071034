import * as React from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styles/styled";

interface IProps {
  title?: string;
  isShow: boolean;
  onClose?: () => void;
  modalType?: string;
}

const { useState, useEffect, useMemo, useCallback } = React;
const $modalRoot = document.getElementById("modal-root") as HTMLDivElement;

const StyledComponent = styled.div<{ active: boolean }>`
  ${({ active, theme: { sizes, colors } }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    .modal {
      &__body{
        caption{
          font-size: 18px;
          font-weight: bold;
          color: ${colors.nero};
        }
        table.liability-coverage-table{
          border-color: ${colors.whiterSmoke};
          th{
            font-size: 16px !important;
          }
          tbody{
            td, tr{
              font-size: 14px;
              font-weight: normal;
              color: ${colors.nero};
            }
          }
        }
      }
      &__background {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 500ms ease;
      }

      &__container {
        width: 85%;
        max-width: 420px;
        min-width: 300px;
        padding: 24px 20px;
        position: relative;
        z-index: 2;
        border-radius: ${sizes.borderRadius};
        background-color: white;
        transform: translateY(-35px);
        opacity: 0;
        transition: transform 500ms, opacity 500ms ease;

        &.insurance-comparison-half-modal, &.service-agreement-half-modal, &.request-insurance-date-half-modal{
          width: 100%;
          position: absolute;
          bottom: 0;
          transform: none;
          transition: none;
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
          padding-bottom: 50px;
        }
      }

      &__title {
        padding-bottom: 15px;
        font-size: 17px;
        font-weight: 600;
      }
    }

    ${
      active &&
      css`
        .modal {
          &__background {
            opacity: 1;
          }

          &__container {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `
    }
  `}
`;

const Modal: React.FC<IProps> = ({
  title,
  isShow,
  onClose,
  children,
  modalType,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isShow) {
      return;
    }
    setTimeout(() => {
      setIsMounted(true);
    });
  }, [isShow]);

  const handleClose = useCallback(() => {
    if (!onClose) {
      return undefined;
    }
    if (modalType !== "agreementModal") {
      setIsMounted(false);
      setTimeout(() => {
        onClose();
      }, 500);
    }
  }, [onClose, modalType]);

  const [modalClasses, setModalClasses] = useState("modal__container ");

  useEffect(() => {
    if (modalType === "liabilityCoverageComparisonModal") {
      setModalClasses("modal__container insurance-comparison-half-modal");
    } else if (modalType === "agreementModal") {
      setModalClasses("modal__container service-agreement-half-modal");
    } else if (modalType === "showPossibleDateToReceiveInsuranceEstimation") {
      setModalClasses("modal__container request-insurance-date-half-modal");
    }
  }, [modalType]);

  const element = useMemo(() => {
    if (!isShow) {
      return null;
    }

    return (
      <StyledComponent active={isMounted}>
        <div className="modal__background" onClick={handleClose} />
        <div className={modalClasses}>
          {title && <h1 className="modal__title">{title}</h1>}
          <div className="modal__body">{children}</div>
        </div>
      </StyledComponent>
    );
  }, [isShow, isMounted, title, handleClose, children, modalClasses]);

  return ReactDOM.createPortal(element, $modalRoot);
};

export default Modal;
