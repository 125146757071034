import * as React from "react";
import Button from "elements/form/button";
import Modal from "elements/helper/modal";
import styled, { css } from "styles/styled";

import { closeWebview } from "core/appLink/global";

interface IProps {
  action: string;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    .requestInsuranceEstimationNotificationModal {
      &__header {
        font-size: 18px;
        font-weight: bold;
        color: ${colors.nero};
        margin-bottom: 16px;
      }
      &__label {
        border-radius: 16px;
        background-color: ${colors.gray300};
        padding: 16px 16px;
        position: relative;
        margin-bottom: 16px;
      }
      &__link {
        text-decoration: underline;
        position: absolute;
        right: 5%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: ${colors.nobel};
      }
      &__summary {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        color: ${colors.nero};
        text-align: left;
        margin-bottom: 32px;
        &--bold {
          font-weight: 600;
        }
      }
      &__button {
        height: 56px;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        border-radius: 8px;
        background-color: ${colors.black};
      }
    }
  `}
`;

const PreventNewUserModal: React.FC<IProps> = ({ action }) => {
  return (
    <Modal
      modalType="showPossibleDateToReceiveInsuranceEstimation"
      isShow={action === "preventNewUserModal"}
    >
      <StyledComponent>
        <div className="requestInsuranceEstimationNotificationModal__header">
          카닥 자동차 보험 리뉴얼
        </div>
        <br />
        <div className="requestInsuranceEstimationNotificationModal__summary">
          카닥 자동차보험 서비스가 리뉴얼 중입니다!
          <br />
          <br />
          10월 중 새로운 모습으로 찾아오겠습니다.
        </div>
        <Button
          className="requestInsuranceEstimationNotificationModal__button"
          color="dark"
          onClick={() => closeWebview(true)}
        >
          홈으로 이동
        </Button>
      </StyledComponent>
    </Modal>
  );
};

export default PreventNewUserModal;
