import { getAppInfo } from "core/appLink/global";
import { setRepresentMycar } from "core/api/mycar";
import { getUserkey } from "core/functions/userkey";

const parsedWindow = window as any;

parsedWindow.app = null;
parsedWindow.setAppInfo = (jsonString: string) => {
  parsedWindow.app = JSON.parse(jsonString);
};
getAppInfo("setAppInfo");

parsedWindow.changedSelectedMycaCallback = (json: string) => {
  const userkey = getUserkey();
  const { userCarId } = JSON.parse(json);

  if (!userkey || !userCarId) {
    document.location.reload();
    return;
  }

  setRepresentMycar(userkey, userCarId).then(() => {
    document.location.reload();
  });
};
