/* eslint-disable */
import React from "react";

import { useDynamicIcon } from "hooks/useDynamicMedia";

import styled, { css } from "styled-components";

const StyledModalServiceHeader = styled.header`
  ${({ theme: { myCarV5Colors } }) => {
    return css`
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      .icon {
        width: 32px;
      }
      .header {
        font-size: 18px;
        font-weight: bold;
        color: ${myCarV5Colors.v5_gray900};
        margin-bottom: 0;
        padding-left: 8px;
      }
    `;
  }}
`;

const ModalServiceHeader = props => {
  const {
    headerMeta: { iconUrl, header },
  } = props;

  const icon = useDynamicIcon(iconUrl);

  return (
    <StyledModalServiceHeader>
      <img className="icon" src={icon} alt="service icon" />
      <h1 className="header">{header}</h1>
    </StyledModalServiceHeader>
  );
};

export default ModalServiceHeader;
