/* eslint-disable */
import React, { useState, useEffect } from "react";

import Picker from "react-mobile-picker";

import styled, { css } from "styled-components";

import { useRecoilState } from "recoil";
import insuranceQnaStateAtom from "recoil/atoms/insuranceQnaStateAtom";

const StyledScrollPicker = styled.section`
  ${({ theme: { myCarV5Colors } }) => {
    return css`
      position: relative;
      .r-icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      ${"" /* overriding react-mobile-picker npm package css */}
      .picker-container {
        .picker-column {
          margin: 0 -50%;
          .picker-scroller {
            transition: 0.1s;
            .picker-item {
              font-size: 14px;
              font-weight: normal;
              color: ${myCarV5Colors.v5_gray700};
              &.picker-item-selected {
                font-size: 20px;
                font-weight: bold;
                color: ${myCarV5Colors.v5_gray900};
              }
            }
          }
        }
        .picker-highlight {
          display: none;
        }
      }
    `;
  }}
`;

const ScrollPicker = props => {
  const [insuranceQnaState, setInsuranceQnaState] = useRecoilState(
    insuranceQnaStateAtom,
  );

  const { userTelecom } = insuranceQnaState;
  const {
    dataIndex,
    scrollMeta,
    modalData,

    defaultScrollMeta,
    defaultScrollValue,
  } = props;

  const scrollPickerValueNameMap = {
    insuranceProvider: "companyName",
    specificationsCarGrade: "gradeName",
    specificationsCarTrim: "trimName",
  };

  const scrollPickerValueName =
    scrollPickerValueNameMap[dataIndex] || "scrollValueName";

  const { scrollData } = scrollMeta;

  const scrollMetaToUseToGenerateScrollValues = scrollData
    ? defaultScrollMeta[scrollData]
    : defaultScrollMeta?.default;

  const currentDefaultScrollValues = scrollMetaToUseToGenerateScrollValues?.map(
    valueMeta => {
      return valueMeta[scrollPickerValueName];
    },
  );

  const { defaultText } = scrollMeta;

  const defaultValue = userTelecom || defaultScrollValue.value;

  const [valueGroups, setValueGroups] = useState({
    title: defaultValue,
  });

  useEffect(() => {
    if (scrollData) {
      setValueGroups({
        title:
          modalData[dataIndex][scrollData] ||
          contentBackendData[scrollData] ||
          defaultValue,
      });
    }
  }, [modalData, scrollData, dataIndex, defaultValue]);

  const optionGroups = {
    title: currentDefaultScrollValues
      ? currentDefaultScrollValues
      : [`${defaultText}-1`, `${defaultText}-2`, `${defaultText}-3`],
  };

  const handleChange = (name, value) => {
    setValueGroups({
      ...valueGroups,
      [name]: value,
    });
    setInsuranceQnaState({
      ...insuranceQnaState,
      userTelecom: value,
    });
  };

  return (
    <StyledScrollPicker>
      {scrollData === "rim" && (
        <img className="r-icon" src={rIcon} alt="r-icon" />
      )}
      <Picker
        height={128}
        valueGroups={valueGroups}
        optionGroups={optionGroups}
        onChange={handleChange}
      />
    </StyledScrollPicker>
  );
};

export default ScrollPicker;
