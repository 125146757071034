import { cardocApiCreator } from "core/api/apiCreator";
import { getUserkey } from "core/functions/userkey";

// 대표 마이카 불러오기
export const getRepresent = (userkey: string) =>
  cardocApiCreator.get(`/v2/userCar/represent?userkey=${userkey}`);

// 대표 마이카 등록
export const setRepresentMycar = (userkey = getUserkey(), userCarId: number) =>
  cardocApiCreator.put("/v2/userCar", {
    action: "represent",
    userCarId,
    userkey,
  });

// 마이카 정보 업데이트
export const updateMycar = (
  userCarId: string,
  params: {
    ownerIdentifier?: string;
    platenum?: string;
  },
) =>
  cardocApiCreator.put("/v2/userCar", {
    action: "updateCar",
    userkey: getUserkey(),
    userCarId,
    ...params,
  });

// 차량번호 유효성 검사
export const validatePlateNumber = (platenum: string) =>
  cardocApiCreator.get(`/v2/plate/gettype/validate/platenum/${platenum}`);
