import * as React from "react";
import styled, { css } from "styles/styled";
import Icon from "elements/helper/icon";

interface IProps {
  checked?: boolean;
  label?: string;
  onClick?: Function;
  boldLabel?: boolean;
}

const Component = styled.span<{ checked: boolean }>`
  ${({ checked, theme: { colors } }) => css`
    display: inline-flex;
    align-items: center;

    .radio-label {
      margin-left: 8px;
      font-size: 14px;
      color: ${colors.nobel};
      font-weight: bold;
      &--bold {
        font-size: 14px;
        font-weight: bold;
        color: ${colors.nero};
      }
    }

    ${
      checked &&
      css`
        .radio-label {
          font-weight: 700;
        }
      `
    }
  `}
`;

const { useState, useEffect } = React;

const Radio: React.FC<IProps> = ({
  checked = false,
  label,
  onClick,
  boldLabel,
}) => {
  const [radioTextClasses, setRadioTextClasses] = useState("radio-label");
  useEffect(() => {
    if (boldLabel) {
      setRadioTextClasses("radio-label radio-label--bold");
    }
  }, [setRadioTextClasses, boldLabel]);

  return (
    <Component
      checked={checked}
      onClick={() => (!!onClick ? onClick() : undefined)}
    >
      <Icon size="24px" icon={`check-circle-${checked ? "dark" : "gray"}`} />
      {label && <span className={radioTextClasses}>{label}</span>}
    </Component>
  );
};

export default Radio;
