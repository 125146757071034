import * as React from "react";
import styled, { css } from "styles/styled";

// page header
const StyledPageHeader = styled.h1`
  ${({ theme: { colors } }) => css`
    padding-bottom: 24px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.5;
    white-space: pre-wrap;

    span {
      color: ${colors.gray600};
    }

    strong {
      color: ${colors.primary};
    }

    p {
      margin-top: 12px;
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 400;

      i {
        margin-right: 4px;
        position: relative;
        top: 1px;
      }
    }
  `}
`;
const PageHeader: React.FC = ({ children }) => (
  <StyledPageHeader>{children}</StyledPageHeader>
);

// cta button wrap
const StyledCtaFlexBox = styled.div`
  ${({ theme: { sizes } }) => css`
    min-height: calc(100vh - ${sizes.headerHeight});
    min-height: calc(
      100vh - ${sizes.headerHeight} - constant(safe-area-inset-top)
    );
    min-height: calc(100vh - ${sizes.headerHeight} - env(safe-area-inset-top));
    padding-bottom: constant(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-top);
    display: flex;
    flex-direction: column;
  `}
`;
const CtaFlexBox: React.FC = ({ children }) => (
  <StyledCtaFlexBox>{children}</StyledCtaFlexBox>
);

// cta button wrap
const StyledCtaButton = styled.div`
  ${({ theme: { sizes } }) => css`
    width: 100%;
    margin-top: auto;
    padding: 16px 0;
  `}
`;
const CtaButton: React.FC = ({ children }) => (
  <StyledCtaButton>{children}</StyledCtaButton>
);

// page template type
type PageTempType = {
  PageHeader: typeof PageHeader;
  CtaFlexBox: typeof CtaFlexBox;
  CtaButton: typeof CtaButton;
};

// main template
const StyledPageTemp = styled.div`
  ${({ theme: { sizes } }) => css`
    padding: 0 20px 0;
  `}
`;
const PageTemp: React.FC & PageTempType = ({ children }) => (
  <StyledPageTemp>{children}</StyledPageTemp>
);

PageTemp.PageHeader = PageHeader;
PageTemp.CtaFlexBox = CtaFlexBox;
PageTemp.CtaButton = CtaButton;
export default PageTemp;
