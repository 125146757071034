import * as React from "react";
import Button from "elements/form/button";
import Modal from "elements/helper/modal";
import styled, { css } from "styles/styled";

import { toast, closeWebview } from "core/appLink/global";

const { useState, useEffect } = React;

interface IProps {
  action: string;
  onClose: () => void;
  possibleDateToRenewInsurance: string;
  userSelectedMonth?: string;
}

const StyledComponent = styled.div`
  ${({ theme: { colors } }) => css`
    .requestInsuranceEstimationNotificationModal {
      &__header {
        font-size: 18px;
        font-weight: bold;
        color: ${colors.nero};
        margin-bottom: 16px;
      }
      &__label {
        border-radius: 16px;
        background-color: ${colors.gray300};
        padding: 16px 16px;
        position: relative;
        margin-bottom: 16px;
      }
      &__link {
        text-decoration: underline;
        position: absolute;
        right: 5%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: right;
        color: ${colors.nobel};
      }
      &__summary {
        font-size: 16px;
        line-height: 24px;
        font-weight: normal;
        color: ${colors.nero};
        text-align: left;
        margin-bottom: 32px;
        &--bold {
          font-weight: 600;
        }
      }
      &__button {
        height: 56px;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        border-radius: 8px;
        background-color: ${colors.black};
      }
    }
  `}
`;

const RequestInsuranceEstimationNotificationModal: React.FC<IProps> = ({
  action,
  onClose,
  possibleDateToRenewInsurance,
  userSelectedMonth,
}) => {
  const checkIfCanCloseModal = (confirm?: string) => {
    if (confirm) {
      onClose();
      closeWebview(true);
      toast(`보험 견적 예약 신청이 완료 되었습니다.`, "native");
    }
  };

  return (
    <Modal
      modalType="showPossibleDateToReceiveInsuranceEstimation"
      isShow={action === "showPossibleDateToReceiveInsuranceEstimation"}
    >
      <StyledComponent>
        <div className="requestInsuranceEstimationNotificationModal__header">
          보험견적 요청 완료
        </div>
        <div className="requestInsuranceEstimationNotificationModal__label">
          {userSelectedMonth
            ? "보험 가입 최적 시점인 "
            : "보험 갱신일 2주 전인 "}
          <span className="requestInsuranceEstimationNotificationModal__summary requestInsuranceEstimationNotificationModal__summary--bold">
            {possibleDateToRenewInsurance}&nbsp;
          </span>
          <br />
          최저가 보험 견적서를 보내 드리겠습니다
        </div>
        <div className="requestInsuranceEstimationNotificationModal__summary">
          {userSelectedMonth
            ? "위 날짜 이전에 보험료 견적을 원하실 경우"
            : "고객님의 갱신 계약 가능 시점에 "}
          {userSelectedMonth && <br />}
          <strong className="requestInsuranceEstimationNotificationModal__summary requestInsuranceEstimationNotificationModal__summary--bold">
            {userSelectedMonth
              ? "보험 갱신일 30일 이내에"
              : "최저가 보험견적서"}
          </strong>
          {!userSelectedMonth && <br />}
          {userSelectedMonth
            ? " 재신청 부탁드립니다"
            : " 를 보내드리도록 하겠습니다"}
        </div>
        <Button
          className="requestInsuranceEstimationNotificationModal__button"
          color="dark"
          onClick={() => checkIfCanCloseModal("confirm")}
        >
          확인
        </Button>
      </StyledComponent>
    </Modal>
  );
};

export default RequestInsuranceEstimationNotificationModal;
