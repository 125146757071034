import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

import styled, { css } from "styled-components";

import imageOne from "./images/loading-img-1.png";
import imageTwo from "./images/loading-img-2.png";
import imageThree from "./images/loading-img-3.png";
import imageFour from "./images/group-8.png";

import Icon from "elements/helper/icon";

const StyledCalculationLoading = styled.section`
  ${({ opaque }) => {
    return css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: ${opaque ? "100" : "1"};
      background-color: ${opaque ? "#ffffff" : ""};
      .carousel {
        padding-top: 194px;
        @media only screen and (max-width: 360px) {
          padding-top: 97px;
        }
        .control-dots {
          .dot {
            width: 6px;
            height: 6px;
            margin: 0 6px 0 0;
            border-radius: 3px;
            background-color: #e6e6e6;
            &.selected {
              background-color: #222222;
            }
          }
        }
      }
      .carousel-text {
        padding-top: 32px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #5c5c5c;
        @media only screen and (max-width: 360px) {
          padding-top: 16px;
        }
      }
      .countdown-timer-container {
        position: absolute;
        left: 50%;
        bottom: 147px;

        @media only screen and (max-width: 480px) {
          bottom: 73px;
        }
        transform: translateX(-50%);
        .display-seconds {
          font-size: 12px;
          color: #5c5c5c;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .loading-indicator {
        position: absolute;
        left: 50%;
        bottom: 136px;

        @media only screen and (max-width: 480px) {
          bottom: 73px;
        }
        transform: translateX(-50%);
        i {
          animation: rotation 0.6s linear infinite;
        }
      }
    `;
  }}
`;

export const CalculationLoading = props => {
  const { opaque } = props;
  const [textIndexToShow, setTextIndexToShow] = useState(0);
  const handleChange = index => {
    console.log("handleChange change", index);
    setTextIndexToShow(index);
  };
  const textArray = [
    "전체 11개 보험사를 모두 확인하고<br/>최저가를 알려드려요",
    "보험료 확인 후<br/>바로 가입하실 수 있어요",
    "보험이 어렵다면 전문가 상담부터<br/>가입까지 한 번에 가능해요",
    "매년 잊지 않고 최저가 보험을<br/>찾을 수 있도록 도와드려요",
  ];

  const [syncedRemainingTime, setSyncedRemainingTime] = useState(20);
  return (
    <StyledCalculationLoading opaque={opaque}>
      <div>
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          autoPlay
          onChange={handleChange}
          interval={2000}
        >
          <div>
            <img src={imageOne} alt="calculation loading" />
          </div>
          <div>
            <img src={imageTwo} alt="calculation loading" />
          </div>
          <div>
            <img src={imageThree} alt="calculation loading" />
          </div>
          <div>
            <img src={imageFour} alt="calculation loading" />
          </div>
        </Carousel>
      </div>
      <div
        className="carousel-text"
        dangerouslySetInnerHTML={{ __html: textArray[textIndexToShow] }}
      />
      {syncedRemainingTime > 0 ? (
        <div className="countdown-timer-container">
          <CountdownCircleTimer
            isPlaying
            duration={20}
            colors="#686e72"
            size={40}
            strokeWidth={3}
          >
            {({ remainingTime }) => {
              setSyncedRemainingTime(remainingTime);
              return (
                <div className="display-seconds">{`${remainingTime}초`}</div>
              );
            }}
          </CountdownCircleTimer>
        </div>
      ) : (
        <div className="loading-indicator">
          <Icon type="png" size="48px" icon="loading-indicator" />
        </div>
      )}
    </StyledCalculationLoading>
  );
};

export default CalculationLoading;
