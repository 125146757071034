import { IQuestionItem } from "core/interface/qnaInterface";

const questions: IQuestionItem[] = [
  {
    question: "기존 보험 갱신이신가요?\n신규가입이신가요?",
    required: "string",
    items: [
      {
        type: "radio",
        name: "isRenew",
        radioItems: [
          {
            value: "보험갱신",
            descriptions: [
              "<strong>보험갱신 (기존 차량 보험가입)</strong>",
              "<p>자동차보험 만기일 30일내의 차량만 조회 가능합니다.</p>",
              "<p>보험 갱신일 경우 차량번호를 꼭 입력해주세요.</p>",
            ],
            validateMycar: {
              columms: ["platenum"],
              message: {
                platenum: "보험 갱신일 경우 차량번호를 입력해주세요.",
              },
            },
          },
          {
            value: "신규구매차량 보험가입",
            descriptions: ["<strong>신규 구매차량의 경우 중고차를 포함"],
          },
        ],
      },
    ],
  },
  {
    question: "원하시는 담보를\n선택해주세요",
    required: "string",
    description: {
      text: '<span>실속형? 프리미엄?</span> <strong class="strong--blue">담보 알아보기</strong>',
      action: "liabilityCoverageComparisonModal",
    },
    addDescription: "* 담보는 상담 및 가입 시 자유롭게 변경 가능합니다.",
    items: [
      {
        type: "radio",
        name: "isRenew",
        radioItems: [
          {
            value: "실속형 담보",
          },
          {
            value: "프리미엄 담보",
          },
        ],
      },
    ],
  },
  // integrated user flow UI
  {
    question: "보험료 조회를 위해\n개인정보를 입력해주세요",
    questionType: "integrated",
    mycarColumn: "ownerName",
    buttonText: "보험견적 요청하기",
    integratedQuestions: [
      {
        question: "보험료 조회를 위해\n개인정보를 입력해주세요",
        required: "string",
        mycarColumn: "ownerName",
        agreement: false,
        items: [
          {
            type: "string",
            label: "이름",
            placeholder: "홍길동",
          },
        ],
      },

      // TODO: Think of better way the order of questions can automatically be reflected.
      {
        question: "피보험자의 주민등록번호 혹은\n사업자번호를 입력해주세요",
        required: "identifier",
        agreement: false,
        agreementMessage:
          "입력하신 정보는 암호화되어 안전하게 저장되며,<br /><strong>보험료 조회를 위해서만 사용</strong>됩니다.",
        mycarColumn: "ownerIdentifier",
        errorMessage: "정확한 주민등록번호 또는 사업자번호를 입력해주세요",
        buttonText: "보험견적 요청하기",
        items: [
          {
            type: "identifier",
            label: "주민등록번호 13자리",
            placeholder: "주민등록번호 13자리",
          },
        ],
      },
      {
        question: "피보험자의 휴대폰 번호를\n입력해주세요",
        required: "telNumber",
        mycarColumn: "ownerMobileNum",
        authUserColumn: "mobilenum",
        errorMessage: "정확한 휴대폰 번호를 입력해주세요.",
        items: [
          {
            type: "telNumber",
            label: "휴대전화번호",
            placeholder: "휴대전화번호",
          },
        ],
      },
    ],
  },
  // Legacy way of declaring questions. (non-integrated questions user flow)
  // {
  //   question: '보험료 조회를 위해\n본인 인증을 해주세요',
  //   required: 'string',
  //   mycarColumn: 'ownerName',
  //   agreement: false,
  //   items: [
  //     {
  //       type: 'string',
  //       label: '이름 또는 사업자명',
  //       placeholder: '예) 홍길동',
  //     },
  //   ],
  // },
  // {
  //   question: '피보험자의 휴대폰 번호를\n입력해주세요',
  //   required: 'telNumber',
  //   mycarColumn: 'ownerMobileNum',
  //   authUserColumn: 'mobilenum',
  //   errorMessage: '정확한 휴대폰 번호를 입력해주세요.',
  //   items: [
  //     {
  //       type: 'telNumber',
  //       label: '휴대전화번호',
  //       placeholder: '예) 010-1234-1234',
  //     },
  //   ],
  // },
  // {
  //   question: '피보험자의 주민등록번호 혹은\n사업자번호를 입력해주세요',
  //   required: 'identifier',
  //   agreement: false,
  //   agreementMessage:
  //     '입력하신 정보는 암호화되어 안전하게 저장되며,<br /><strong>보험료 조회를 위해서만 사용</strong>됩니다.',
  //   mycarColumn: 'ownerIdentifier',
  //   errorMessage: '정확한 주민등록번호 또는 사업자번호를 입력해주세요',
  //   buttonText: '보험견적 요청하기',
  //   items: [
  //     {
  //       type: 'identifier',
  //       label: '주민등록번호 또는 사업자번호',
  //       placeholder: '예) 910123-1234567',
  //     },
  //   ],
  // },
];

export default questions;
