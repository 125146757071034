import { atom } from "recoil";

const insuranceQnaStateAtom = atom({
  key: "insuranceQnaState",
  default: {
    unmaskedIdentifier: "",
    userTelecom: "통신사 선택",
  },
});

export default insuranceQnaStateAtom;
