/* eslint-disable */
import React, { useState, useEffect } from "react";

import CancelAndConfirmButtons from "components/buttons/CancelAndConfirmButtons";
import ModalServiceHeader from "components/Modals/ModalServiceHeader";
import ScrollPickerArea from "components/Modals/ScrollPickerArea";

import styled, { css } from "styled-components";

const StyledHalfModal = styled.div`
  ${({ theme: { myCarV5Colors } }) => {
    return css`
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 24px 20px 24px;
      border-top-right-radius: 24px;
      border-top-left-radius: 24px;
      background-color: ${myCarV5Colors.v5_white100};
    `;
  }}
`;

const HalfModal = props => {
  const {
    modalServiceMeta,
    setModalServiceMeta,
    mycarMetaToSave,
    setMycarMetaToSave,

    modalMeta,
    setModalMeta,
    closeModal,
  } = props;

  const handleClick = e => {
    // NOTE: because clicking on the modal and dimmed area dismisses the modal,
    // call e.stopPropagation() so that the visible modal area can have its own actions.
    e.stopPropagation();
  };

  const handleConfirm = () => {
    document.getElementById("telNumber")?.focus();

    closeModal();
  };

  const [cancelText] = useState("취소");
  const [confirmText] = useState("확인");

  const content = {
    scrollMeta: {
      scrollDataType: "singleLineText",
      scrolls: [
        {
          defaultText: "통신사 선택하기",
        },
      ],
    },
  };

  const defaultScrollMeta = {
    default: [
      { scrollValueName: "SKT" },
      { scrollValueName: "KT" },
      { scrollValueName: "LG U+" },
      { scrollValueName: "알뜰폰(SKT)" },
      { scrollValueName: "알뜰폰(KT)" },
      { scrollValueName: "알뜰폰(LG U+)" },
    ],
  };

  const defaultScrollValue = {
    value: "SKT",
  };

  return (
    <StyledHalfModal onClick={handleClick}>
      <ModalServiceHeader
        headerMeta={{
          iconUrl: "ico-maintenance-insurance.svg",
          header: "보험",
        }}
      />
      <ScrollPickerArea
        content={content}
        defaultScrollMeta={defaultScrollMeta}
        defaultScrollValue={defaultScrollValue}
        label={"통신사를 선택 해주세요"}
      />
      <CancelAndConfirmButtons
        cancelText={cancelText}
        confirmText={confirmText}
        onCancel={closeModal}
        onConfirm={handleConfirm}
        modalMeta={modalMeta}
      />
    </StyledHalfModal>
  );
};

export default HalfModal;
